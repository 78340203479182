(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
            .service('ProfileService',
                ['$resource', ProfileService]);

    function ProfileService($resource) {
        return $resource('/api/internal/profile/:id', {}, {
            show: {
                method: 'GET',
                url: '/api/internal/profile/show'
            },
            update: {
                method: 'PUT',
                url: '/api/internal/profile/update'
            },
        });
    }

})(window.angular, window.Utilities);