(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
        .service('InstancesService',
        ['$resource', InstancesService]);

    function InstancesService($resource) {
        return $resource('/api/internal/customers/current/instances', {}, {
            'getCurrent': {
                method: 'GET'
            }
        });
    }

})(window.angular, window.Utilities);