(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("InstancesController",
                    ["$scope", "InstancesService", InstancesController]);

    /*
     * Controller for displaying instances
     */
    function InstancesController($scope, InstancesService) {

        // must be called like this to ensure parent initialization
        InstancesController.parent.constructor.apply(this, 
                                                [$scope]);

        var vm = this;
       
        vm.instances = [];
        
        // not actually doing anything in this case, left as an example for future forms
        vm.initController = function() {
           InstancesController.parent.initController.apply(this, arguments);
        
           vm.loadCurrentInstances();
        };

        vm.loadCurrentInstances = function() {
            InstancesService.getCurrent(null, function(response) {
                vm.instances = response.data;
            });
        };
    }
    
    Utilities.inherit(InstancesController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);