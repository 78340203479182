/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function (root, angular) {
    "use strict";

    /**
     * Service to extract and store language-specific texts from server supplied JSON arrays
     * to avoid duplicating server texts in .js files.
     * 
     */
    angular.module('common.services')
            .service('I18nService', I18nServiceConstructor);

    // this will be global for all instances
    // no matter if Angular creates new I18nService
    var currentLocale = "en",
            texts = {}; // shoould be inited with addLocaleTexts()

    // keep a global instance, independent of Angular
    var i18nService = {};

    function I18nServiceConstructor() {
        // ensure we return our singleton
        return i18nService;
    }

    /**
     * Extends currently cached locale strings with more items.
     * 
     * @param {string} serverLocale
     * Optional locale ISO2 identifier: en, lv, ru etc. If not received, current locale will be used.
     * 
     * @param {object} serverTexts
     * Expected serverTexts format is an object with root key matching serverLocale:
     * serverTexts = {"en":{"someKey":"some value"}}
     * 
     * @returns {undefined}
     */
    i18nService.addLocaleTexts = function (serverLocale, serverTexts) {
        // this is expected to be a singleton
        // shoould be inited in angular ng-init

        // merge server texts in for current locale, not overwrite all texts

        // if locale not passed, use default one
        serverLocale = serverLocale || currentLocale;

        if (!texts[serverLocale]) {
            texts[serverLocale] = {};
        }

        angular.extend(texts[serverLocale], serverTexts[serverLocale]);
    };

    /**
     * Sets current locale which will be used for all the next getText() calls.
     * 
     * @param {string} serverLocale
     * Locale to switch to. ISO2 identifier: en, lv, ru etc.
     * 
     * @returns {undefined}
     */
    i18nService.setCurrentLocale = function (serverLocale) {
        currentLocale = serverLocale;
    };
    
    /**
     * Returns locale id.
     * 
     * @returns {serverLocale|String}
     */
    i18nService.getCurrentLocale = function () {
        return currentLocale;
    };
    
    /**
     * Tries to retrieve the text by key using locale as set by setCurrentLocale().
     * 
     * @param {string} key
     * Key of text to retrieve. Keys can be nested, e.g. "errors.something.bad"
     * 
     * @returns {string}
     * The text or the key, if text not found.
     */
    i18nService.getText = function (key) {
        // sanity check
        if(!key){
            return key;
        }
        
        var root = texts[currentLocale];
        if (!root){
            return key;
        }
            
        // extract last
        var keys = key.split('.');

        // recursive search for final value
        keys.forEach(function (value) {
            if (!root) {
                return;
            }
            root = root[value];
        });

        return root || key;
    };
    
    
    // make sure both maps are in sync with server side formats
    var formatHrToAngularMap = {
        
        // ISO 8601 derived standard, used also by SQL servers
        // ignored trailing Z for UTC
        // and replaced T with space for human readability
        // - timezone is solved in different settings,
        // server dates are always UTC
        "yyyy-mm-dd" : "yyyy-MM-dd",
        "yyyy-mm-dd hh:mm" : "yyyy-MM-dd HH:mm",
        "yyyy-mm-dd hh:mm:ss" : "yyyy-MM-dd HH:mm:ss",
        "yyyy-mm-ddThh:mm:ss" : "yyyy-MM-ddTHH:mm:ss",
        
        // legal formats and their human readable aliases
        // usually also mapped on server side to server specific formats
        "yyyy.mm.dd." : "yyyy.MM.dd.",
        "yyyy.mm.dd. hh:mm" : "yyyy.MM.dd HH:mm",
        "yyyy.mm.dd. hh:mm:ss" : "yyyy.MM.dd HH:mm:ss",
        
        "dd.mm.yyyy." : "dd.MM.yyyy.",
        "dd.mm.yyyy. hh:mm" : "dd.MM.yyyy. HH:mm",
        "dd.mm.yyyy. hh:mm:ss" : "dd.MM.yyyy. HH:mm:ss",
        
        // full time format
        "hh:mm:ss" : "HH:mm:ss",
        
        // British
        "dd/mm/yyyy" : "dd/MM/yyyy",
        "dd/mm/yyyy hh:mm" : "dd/MM/yyyy HH:mm",
        "dd/mm/yyyy hh:mm:ss" : "dd/MM/yyyy HH:mm:ss"
        
        // TODO: other possible languages and formats
    };
    
    var formatHrToMomentMap = {
        
        // ISO 8601 derived standard, used also by SQL servers
        // ignored trailing Z for UTC
        // and replaced T with space for human readability
        // - timezone is solved in different settings,
        // server dates are always UTC
        "yyyy-mm-dd" : "YYYY-MM-DD",
        "yyyy-mm-dd hh:mm" : "YYYY-MM-DD HH:mm",
        "yyyy-mm-dd hh:mm:ss" : "YYYY-MM-DD HH:mm:ss",
        "yyyy-mm-ddThh:mm:ss" : "YYYY-MM-DDTHH:mm:ss",
        
        // legal formats and their human readable aliases
        // usually also mapped on server side to server specific formats
        "yyyy.mm.dd." : "YYYY.MM.DD.",
        "yyyy.mm.dd. hh:mm" : "YYYY.MM.DD HH:mm",
        "yyyy.mm.dd. hh:mm:ss" : "YYYY.MM.DD HH:mm:ss",
        
        "dd.mm.yyyy." : "DD.MM.YYYY.",
        "dd.mm.yyyy. hh:mm" : "DD.MM.YYYY. HH:mm",
        "dd.mm.yyyy. hh:mm:ss" : "DD.MM.YYYY. HH:mm:ss",
        
        // full time format
        "hh:mm:ss" : "HH:mm:ss",
        
        // British
        "dd/mm/yyyy" : "DD/MM/YYYY",
        "dd/mm/yyyy hh:mm" : "DD/MM/YYYY HH:mm",
        "dd/mm/yyyy hh:mm:ss" : "DD/MM/YYYY HH:mm:ss"
        
        // TODO: other possible languages and formats
    };
    
    var globalizationSettings = {};
    var serverGlobalizationSettings = {};
    
    /**
     * Maps human readable platform agnostic popular datetime formats
     * to Bootstrap Datepicker component format
     * using format key as given in globalizationSettings
     * which should first be inited by setGlobalizationSettings()
     * 
     * @param string hrFormatKey
     * @returns string
     */
    i18nService.getCurrentAngularDatetimeFormatByKey = function (hrFormatKey) {
       var hrFormat = globalizationSettings[hrFormatKey];
        
       return formatHrToAngularMap[hrFormat] || "BADFORMAT";
    };

    /**
     * Maps human readable platform agnostic popular datetime formats
     * to Bootstrap Datepicker component format
     * using format key as given in serverGlobalizationSettings
     * which should first be inited by setGlobalizationSettings()
     * 
     * @param string hrFormatKey
     * @returns string
     */
    i18nService.getServerAngularDatetimeFormatByKey = function (hrFormatKey) {
       var hrFormat = serverGlobalizationSettings[hrFormatKey];
        
       return formatHrToAngularMap[hrFormat] || "BADFORMAT";
    };
    
   
    /**
     * Maps human readable platform agnostic popular datetime formats
     * to Moment.js format
     * using format key as given in globalizationSettings
     * which should first be inited by setGlobalizationSettings()
     * 
     * @param string hrFormatKey
     * @returns string
     */
    i18nService.getCurrentMomentDatetimeFormatByKey = function (hrFormatKey) {
       var hrFormat = globalizationSettings[hrFormatKey];
        
       return formatHrToMomentMap[hrFormat] || "BADFORMAT";
    };

    /**
     * Maps human readable platform agnostic popular datetime formats
     * to Moment.js format
     * using format key as given in serverGlobalizationSettings
     * which should first be inited by setGlobalizationSettings()
     * 
     * @param string hrFormatKey
     * @returns string
     */
    i18nService.getServerMomentDatetimeFormatByKey = function (hrFormatKey) {
       var hrFormat = serverGlobalizationSettings[hrFormatKey];
        
       return formatHrToMomentMap[hrFormat] || "BADFORMAT";
    };    
    
    /**
     * Returns current timezone to use in UI time conversions.
     */
    i18nService.getCurrentTimezone = function () {
       return globalizationSettings["timezone"];
    };

    /**
     * Returns current timezone to use in UI time conversions.
     */
    i18nService.getServerTimezone = function () {
       return serverGlobalizationSettings["timezone"];
    };
    
    /**
     * Configures server formats and current user UI formats to convert from / to.
     * 
     * @param Object serverSettings
     * @param Object uiSettings
     */
    i18nService.setGlobalizationSettings = function (serverSettings, uiSettings) {
        
        serverGlobalizationSettings = serverSettings;
        globalizationSettings = uiSettings;
       
    };

    // keep a global instance, accessible independently of Angular
    root.I18nService = i18nService;

})(window, window.angular);