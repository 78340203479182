(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
        .service('PhysicalPersonRegistrationService',
        ['$resource', PhysicalPersonRegistrationService]);

    function PhysicalPersonRegistrationService($resource) {
        return $resource('/api/internal/physical-person-registrations', {}, {
            'save': {
                method: 'POST'
            },
            'saveAndLink': {
                url:    '/api/internal/physical-person-registrations/register-and-link',
                method: 'POST'
            },
            'logOut': {
                url:    '/api/internal/physical-person-registrations/logout',
                method: 'POST'
            },
            'checkUserInstance': {
                url:    '/api/internal/physical-person-registrations/user-instance',
                method: 'POST'
            }
        });
    }

})(window.angular, window.Utilities);