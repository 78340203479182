(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("ClientPortalGridController",
                    ["$scope", "ClientService", ClientPortalController]);

    /*
     * Controller for displaying instances
     */
    function ClientPortalController($scope, ClientService) {
        // must be called like this to ensure parent initialization
        ClientPortalController.parent.constructor.apply(this, [$scope]);

        var vm = this;

        vm.columnDefs = [
            {headerName: "Email", field: "email", sort: 'desc', width: 140}
        ];

        vm.gridOptions = {
            columnDefs: vm.columnDefs,
            rowData: [],
            pagination: true,
            paginationPageSize: 10,
            enableSorting: true,
            rowSelection: 'single',
            rowHeight: 75,
            sortingOrder: ['desc','asc',null]
        };

        vm.selectedInstance = 0; //TODO: Move this to scope so this controller can see selectedInstance

        vm.entity = {};

        vm.initController = function() {
            ClientPortalController.parent.initController.apply(this, arguments);

            ClientService.clientData(null, function(response) {
                console.log("Respnse", response);
                vm.entity = response.data;
                console.log("Respnse", response);
                console.log("vm.entity", vm.entity);
                vm.gridOptions.api.setRowData(vm.entity[0]);
            });
        };

    }

    Utilities.inherit(ClientPortalController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);

