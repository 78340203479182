(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("ProfileEditController",
                    ["$scope", "ProfileService", ProfileEditController]);

    function ProfileEditController($scope, ProfileService) {

        // must be called like this to ensure parent initialization
        ProfileEditController.parent.constructor.apply(this, [$scope]);

        var vm = this;
        vm.selectedInstance = 0;

        vm.model = {};

        vm.initController = function() {
            ProfileEditController.parent.initController.apply(this, arguments);

            ProfileService.show(null, function(response) {
                vm.model = response.data;
            });
        };
        vm.save = function() {
            ProfileService.update({
                }, vm.model, function(response) {
                window.location.href="/profile";
                });

        };

        vm.cancel = function() {
            window.location.href="/profile";
        };
    }

    Utilities.inherit(ProfileEditController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);

