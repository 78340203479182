(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
        .service('SubscribeService',
        ['$resource', SubscribeService]);

    function SubscribeService($resource) {
        return $resource('/subscribe', {}, {
            'subscribe': {
                method: 'POST'
            }
            
        });
    }

})(window.angular, window.Utilities);