(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
        .service('SendFormService',
            ['$resource', SendFormService]);

    function SendFormService($resource) {
        return $resource('/send-form', {}, {
            'sendForm': {
                method: 'POST'
            }
        });
    }

})(window.angular, window.Utilities);