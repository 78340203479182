/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function (angular) {
    "use strict";

    angular.module("common.directives")
            .directive("errorAware", [
                "$timeout", "formErrorsConfig", "$interpolate", ErrorAwareDirective])
            .provider("formErrorsConfig", FormErrorsConfigProvider)
            .directive("fieldErrors", [
                "$templateCache", "$compile", "formErrorsConfig",
                FieldErrorsDirective]);

    /* custom directive to stop repeating ngMessages block with all its args 
     * use like <ANY field-errors field="cntrlr.form.field">
     * or
     * <ANY field-errors="{ fieldErrorsTemplateId: "customTemplate" }">
     * where field is mandatory
     * */
    function FieldErrorsDirective($templateCache, $compile, formErrorsConfig) {

        // post-link
        function linkFn(scope, iElem, attrs) {
            var options, fieldErrorsTemplateId, template;

            options = scope.$eval(attrs.fieldErrors);

            // return from received options or default
            fieldErrorsTemplateId = formErrorsConfig.fieldErrorsTemplateId;
            if (options && options.templateId) {
                fieldErrorsTemplateId = options.templateId;
            }

            // modifying HTML manually 
            // because template function is called before compile or link
            // but we need to process internal HTML ourselves
            // using dynamic options

            // is it cached?
            template = $templateCache.get(fieldErrorsTemplateId);
            if (!template) {
                // depends on jQuery
                template = $("#" + fieldErrorsTemplateId).html();
                $templateCache.put(fieldErrorsTemplateId, template);
            }

            iElem.replaceWith($compile(template)(scope));
        }

        // directive declaration
        return {
            restrict: "A",
            // isolate from parent scope - good practice
            scope: {
                field: "=" // two way bind with model
            },
            link: linkFn
        };
    }

    /**
     * Code borrowed from
     * https://github.com/paulyoder/angular-bootstrap-show-errors
     * With lots of modifications.
     */
    function ErrorAwareDirective($timeout, formErrorsConfig, $interpolate) {

        var getShowSuccess, getTrigger, linkFn;
        getTrigger = function (options) {
            var trigger;
            trigger = formErrorsConfig.trigger;
            if (options && (options.trigger !== null)) {
                trigger = options.trigger;
            }
            return trigger;
        };
        getShowSuccess = function (options) {
            var showSuccess;
            showSuccess = formErrorsConfig.showSuccess;
            if (options && (options.showSuccess !== null)) {
                showSuccess = options.showSuccess;
            }
            return showSuccess;
        };
        linkFn = function (scope, el, attrs, formCtrl) {
            var blurred, inputEls, inputNames,
                    options, showSuccess, toggleClasses, trigger;
            blurred = false;
            options = scope.$eval(attrs.showErrors);
            showSuccess = getShowSuccess(options);
            trigger = getTrigger(options);
            
            inputEls = el.find("[name]").toArray();
            // full jquery required. turn to standard array for convenience later

            inputNames = inputEls.map(function(item){
                var inputName = $interpolate(angular.element(item)
                        .attr("name") || "")(scope);

                if (!inputName) {
                    throw "error-aware element has no child input elements with a `name` attribute";
                }
                
                return inputName;
            });

            inputEls.forEach(function(item, ix){
                var elmTrigger = trigger;
                // for buttons, we need enforce click event
                if(item.tagName.toLowerCase() === "button"){
                    elmTrigger += " click";
                }
                
                var evHandler = function () {
                    blurred = true;
                    return toggleClasses(hasAnyInvalidDirtyInput(),
                        hasAllValidDirtyInputs());
                };
                
                angular.element(item).bind(elmTrigger, evHandler);
                var jQobj = $(item);
                
                // specific UI control workarounds

                // datepicker does not send change event when clicking,
                // so we listen to model change instead
                if(typeof(jQobj.attr("datepicker-popup")) != 'undefined'
                    || typeof(jQobj.attr("uib-datepicker-popup")) != 'undefined'){

                    scope.$parent.$watch(function() {
                            if(formCtrl[inputNames[ix]]){
                                return formCtrl[inputNames[ix]].$modelValue;
                            }

                            return null;
                        },
                        function(newValue, oldValue){
                            evHandler();
                        });
                }
            });


            function hasAnyInvalidDirtyInput(){
                var hasAnyInvalid = false; // assume all valid
                inputNames.forEach(function(inputName){
                     
                    if(!formCtrl[inputName]){
                        return; // no such control, skip it
                    }
                    if(formCtrl[inputName].$invalid && formCtrl[inputName].$dirty){
                        hasAnyInvalid = true;// at least one was invalid and modified by user
                    }
                });
                return hasAnyInvalid;
            }

            function hasAllValidDirtyInputs(){
                var hasAllValid = true; // assume all valid
                inputNames.forEach(function(inputName){
                    
                    if(!formCtrl[inputName]){
                        return; // no such control, skip it
                    }
                    
                    // will remain fully valid if only if all are modified and valid
                    hasAllValid = hasAllValid && formCtrl[inputName].$valid && formCtrl[inputName].$dirty;
                });
                
                return hasAllValid;
            }
            
            // separate watches to avoid wrong error/success detection because of dirty checks
            // invalid
            scope.$watch(function () {
                return hasAnyInvalidDirtyInput();
            }, function (invalid) {
                // if no any event before processing, then skip
                if (!blurred) {
                    return;
                }
                return toggleClasses(invalid, null);
            });
            
            // valid
            scope.$watch(function () {
                return hasAllValidDirtyInputs();
            }, function (valid) {
                if (!blurred) {
                    return;
                }
                return toggleClasses(null, valid);
            });
            
            scope.$on("error-aware-check-validity", function (event, formName) {
                // prevent from listening on foreign forms
                if (formName) {
                    if (formCtrl.$name !== formName) {
                        return null;
                    }
                }
                return toggleClasses(hasAnyInvalidDirtyInput(),
                                hasAllValidDirtyInputs());
            });
            
            scope.$on("error-aware-reset", function (event, formName) {
                // prevent from listening on foreign forms
                if (formName) {
                    if (formCtrl.$name !== formName) {
                        return null;
                    }
                }

                return $timeout(function () {
                    el.removeClass("has-error");
                    el.removeClass("has-success");
                    blurred = false;
                    return blurred;
                }, 0, false);
            });

            // custom valid logic - only if dirty
            toggleClasses = function (invalid, valid) {
                   
                // integration with scrollable to redraw scrollbars on errors in forms
                $timeout(function () {
                    scope.$emit("error-aware.changed");
                }, 0);
                
                // check strict true/false to avoid touching values from opposite watches 
                // - these will be passed as null
                if(invalid === true || invalid === false){
                    el.toggleClass("has-error", invalid);
                }
                
                if (showSuccess) {
                    if(valid === true || valid === false){
                        return el.toggleClass("has-success", valid);
                    }
                }
            };

            return toggleClasses;
        };
        return {
            restrict: "A",
            require: "^form",
            compile: function (elem, attrs) {
                // If your HTML code doesn"t have a form-group class, the form group check can be skipped:
                // <div error-aware="{ skipFormGroupCheck: true }">
                if (attrs.errorAware.indexOf("skipFormGroupCheck") === -1) {
                    if (!(elem.hasClass("form-group") || elem.hasClass("input-group"))) {
                        throw "error-aware element does not have the `form-group` or `input-group` class";
                    }
                }
                return linkFn;
            }
        };
    }

    function FormErrorsConfigProvider() {

        // showSuccess - for changing styles when user enters valid values
        // trigger - when to trigger error display classes
        var _showSuccess, _trigger, _fieldErrorsTemplateId;
        _showSuccess = false; 
        // disable by default to avoid marking UI fields with success
        // when they might actually be invalid on the server side
        _trigger = "keyup change"; // be consistent with Angular
        // notice - this won"t work with password fields
        // because they return empty values
        // until user has stepped through the field once or hits submit

        // custom field eror group template to implement in fieldErrors directive
        _fieldErrorsTemplateId = "field-errors";
        this.showSuccess = function (showSuccess) {
            _showSuccess = showSuccess;
            return _showSuccess;
        };
        this.trigger = function (trigger) {
            _trigger = trigger;
            return _trigger;
        };
        this.fieldErrorsTemplateId = function (fieldErrorsTemplateId) {
            _fieldErrorsTemplateId = fieldErrorsTemplateId;
            return _fieldErrorsTemplateId;
        };
        this.$get = function () {
            return {
                showSuccess: _showSuccess,
                trigger: _trigger,
                fieldErrorsTemplateId: _fieldErrorsTemplateId
            };
        };
    }

})(window.angular); 