(function (root, angular) {
    "use strict";
    // our Angular-independent Javascript stuff - lazy init in case exists
    root.App = root.App || {};

    angular.module("amber",
        [ "common.filters",
            "common.directives",
            "common.services",
            "common.controllers",
            "ngResource",
            "ngMessages",
            "ngAnimate",
            "angular-velocity",
            "ui.bootstrap",
            "blockUI",
            "ui.router",
            "LocalStorageModule",
            "ngScrollable",
            "ngFileUpload"])
            .config(["$httpProvider",
                "$provide",
                "blockUIConfig",
                "localStorageServiceProvider",
                "uibDatepickerConfig",
                "uibDatepickerPopupConfig",
                "ngScrollableConfigProvider",
                "msiSelectConfigProvider",
                function ($httpProvider, $provide, blockUIConfig, localStorageServiceProvider,
                            uibDatepickerConfig, uibDatepickerPopupConfig, ngScrollableConfigProvider,
                            msiSelectConfigProvider) {
                    // needed by Laravel to distinguish AJAX requests from others
                    $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

                    // needed to send JSON data with DELETE requests
                    $httpProvider.defaults.headers.delete = {"Content-Type": "application/json;charset=utf-8"};

                    $httpProvider.interceptors.push("HttpRequestInterceptor");

                    // UI blocker
                    // Provide a custom template to use
                    blockUIConfig.templateUrl = "ui-blocker.html"; // defined by id in templates file

                    // Change the default overlay message
                    blockUIConfig.message = "";

                    // Remove default delay to prevent doubleclicks
                    blockUIConfig.delay = 0;

                    // Enable browser navigation blocking
                    blockUIConfig.blockBrowserNavigation = true;

                    // Tell the blockUI service to ignore certain requests
                    blockUIConfig.requestFilter = function (config) {
                        // If the request starts with "/api/quote" ...
                        //if(config.url.match(/^\/api\/quote($|\/).*/)) {
                        //    return false; // ... don"t block it.
                        //}
                        if (config.url.indexOf('send-form') > 0) {
                            return false;
                        }
                    };

                    localStorageServiceProvider.setPrefix("amber");

                    // exception handler to fail harder
                    $provide.decorator("$exceptionHandler", ["$delegate", "$injector", function($delegate, $injector){
                        return function(exception, cause){
                            if(!root.App.unloading){
                                // angular might be in broken state, so we manipulate DOM directly
                                root.document.getElementById("jsfail").style.display = "block";
                                root.document.getElementById("blockui").style.display = "block";

                                $delegate(exception, cause);
                            }
                        };
                    }]);

                    // Bootstrap date picker global configuration
                    uibDatepickerConfig.startingDay = 1;
                    uibDatepickerConfig.showWeeks = false;

                    // somwehat hacky to use I18nService without Angular
                    // but we need date format settings early
                    uibDatepickerPopupConfig.datepickerPopup =
                            window.I18nService.getCurrentAngularDatetimeFormatByKey("date_format");

                    uibDatepickerPopupConfig.showButtonBar = false;

                    ngScrollableConfigProvider.set({
                        enableKinetic: false,
                        legacyScrollMode: true
                    });
             }])

            .run(["$rootScope", "FormValidatorService", "$uibModalStack",
                function ($rootScope, FormValidatorService, $uibModalStack) {
                    // configure links between service instances when rootscope is ready
                    FormValidatorService.listenToServer($rootScope);

                    // make back button handle closing the modal
                    $rootScope.$on("$stateChangeStart", function() {

                        var top = $uibModalStack.getTop();

                        if (top) {
                           $uibModalStack.dismiss(top.key);
                        }
                    });
                }]);

    // catch unload events and silence errors to avoid looking stupid
    window.onbeforeunload = function (e) {
        root.App.unloading = true;
    };

})(window, window.angular);
