(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("RegistrationController",
                    ["$scope", "$window", "blockUI", "I18nService", "FormValidatorService",
                        "RegistrationService", "BindingService",
                        RegistrationController]);

    /*
     * Controller for managing almost all profile related actions
     */
    function RegistrationController($scope, $window, blockUI, I18nService, FormValidatorService,
            RegistrationService, BindingService ) {

        RegistrationController.parent.constructor.apply(this, [$scope, I18nService]);

        var vm = this;
        vm.model = {};
        
        vm.initController = function(newModel) {

            RegistrationController.parent.initController.apply(this, arguments);

            vm.model = angular.copy(newModel);
            // to prevent typing in form while it still loading
            BindingService.safeApply($scope, function() {
                vm.loaded = true;
            });
        };

        vm.signUp = function(signupType) {
            
            if (FormValidatorService.validateWithFocus(vm.form)) {
                RegistrationService.save(vm.model, function(response) {
                    
                    // block before redirect, so the user does not feel stuck
                    blockUI.start();
    
                    $window.location.href = "/jauna-instance/" + signupType +
                                "/success/" + response.data.instance.code;
                        
                }, function(error) {

                });
            }
        };
 
    }

    Utilities.inherit(RegistrationController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);