(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module('amber')
            .controller('ResetPasswordController',
                    ['$scope', 'I18nService', 'FormValidatorService',
                        'AuthService', 'BindingService', '$location',
                        '$timeout',
                        ResetPasswordController]);

    /*
     * Controller for managing almost all profile related actions
     */
    function ResetPasswordController($scope, I18nService, FormValidatorService,
                                     AuthService, BindingService, $location, $timeout) {
        
        // must be called like this to ensure parent initialization
        ResetPasswordController.parent.constructor.apply(this, 
                                                [$scope, I18nService]);
        
        var vm = this;
        vm.emailSent = false;
        vm.passwordChanged = false;
        vm.model = {};
        
        vm.initController = function() {
           ResetPasswordController.parent.initController.apply(this, arguments);
           
            // to prevent typing in form while it still loading
            BindingService.safeApply($scope, function(){ 
                vm.loaded = true;
            });
        };
        
        vm.sendEmail = function ()
        {
            // hide message, if visible
            vm.emailSent = false;  
            
            if(FormValidatorService.validateWithFocus(vm.form)){
                AuthService.email(vm.model, function(response){
                    vm.emailSent = true;
                    
                    // reset form
                    vm.model = {};
                    FormValidatorService.clearErrors(vm.form);  
                });
            }
        };
        
        vm.resetPassword = function (token, login)
        {
            // hide message, if visible
            vm.passwordChanged = false;
            
            // set token manually - angular ignores input values
            vm.model.token = token;

            if(FormValidatorService.validateWithFocus(vm.form)) {
                AuthService.reset(vm.model, function(response) {

                    vm.passwordChanged = true;

                    if (login) {
                       AuthService.login(vm.model , function() {
                           $timeout(function() {
                               window.location = "/";
                           }, 3000);
                       });
                    }

                        // reset form
                        vm.model = {};
                        FormValidatorService.clearErrors(vm.form);
                }, 

                function(response){
                    // special case - invalid token
                    if(response.data.errors.token) {
                        vm.tokenError = response.data.errors.token[0];
                        // reset form
                        vm.model = {};
                        FormValidatorService.clearErrors(vm.form);
                    }
                });
            }
        };
    }

    // class-like JavaScript extending without Angular heavy-weight logic
    Utilities.inherit(ResetPasswordController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);