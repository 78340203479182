/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function (angular) {
    "use strict";

    angular.module('common.services')
            .service('BindingService', 
    ['$sce', '$timeout', BindingService]);

    /**
     * Service with some helpers for data bindings.
     * 
     * @returns BindingService
     */
    function BindingService($sce, $timeout) {

        var that = this;
        
        /**
         * A helper to safely call apply on $scope 
         * to avoid race conditions with Angular's $digets.
         * Call like BindingsService.safeApply($scope);
         * 
         * Borrowed from 
         * https://coderwall.com/p/ngisma/safe-apply-in-angular-js
         *          
         * @param {type} scope
         * Scope to apply.
         * 
         * @param {type} fn
         * Optional function to call when applying.
         * 
         * @returns {undefined}
         */
        this.safeApply = function (scope, fn) {
            var phase = scope.$root.$$phase;
            if (phase === '$apply' || phase === '$digest') {
                // we are already in $apply, can call immediately
                if (fn && (typeof (fn) === 'function')) {
                    fn();
                }
            } else {
                // pass over to Angular to apply it
                scope.$apply(fn);
            }
        };

         /**
         * Actually just a timeout but wrapped in a more implicit name.
         * 
         * @param {type} fn
         * Function to call.
         * 
         * @returns {undefined}
         */
        this.deferApplyScope = function (fn, scope) {
            $timeout(function () {
               fn();
               if(scope){
                   that.safeApply(scope);
               }
            }, 0, true); //  true - Angular should update binidngs
        };


        /**
         * Allows binding to raw unescaped HTML code.
         * 
         * @param {string} htmlCode
         * HTML string to make trustable.
         * 
         * @returns {object}
         * Binding object which will be trusted by Angular
         */
        this.toTrustedHtml = function (htmlCode) {
            return $sce.trustAsHtml(htmlCode);
        };
    }

})(window.angular); 