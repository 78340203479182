(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("MasterController",
                    ["$scope", "$rootScope", "$state", "$window", "$timeout", "I18nService", "localStorageService",
                        "FormValidatorService", "SubscribeService", "AuthService","ClientService", "$uibModal",
                        MasterController]);

    function MasterController($scope, $rootScope, $state, $window, $timeout, I18nService, localStorageService, FormValidatorService,
                              SubscribeService, AuthService,ClientService ,$uibModal) {

        var vm = this;
        vm.isLoaded = false;
        vm.email = null;
        vm.model = {};
        // must be called like this to ensure parent initialization
        MasterController.parent.constructor.apply(this,
                [$scope, I18nService]);

        vm.title = "Amber Housing";

        // set-up global view parts:
        // notification bar bindings
        vm.responseStatus = {};

        vm.initController = function (texts, languages, model) {

            MasterController.parent.initController.apply(this, arguments);
            vm.languages = angular.copy(languages);// no manipulations, take by ref
            vm.showCookieAcceptance = false;

            // Check if user accepted cookies
            if (localStorageService.keys().indexOf("cookiesAcceptance") < 0) {
              vm.showCookieAcceptance = true;
            }

            vm.title = I18nService.getText("titles.amber_housing_central_title");

            if(model) {
                vm.user = angular.copy(model);
                vm.user.fullname = vm.user.name + " " + vm.user.surname;

                // go to instances state,
                // if no other state
                // and no path url given for authorized user

                //if($window.location.pathname == "/"){
                //    $timeout(function(){
                //        if(!$state.$current.name ||
                //            $state.$current.name == "master"){
                //            $state.go("instances");
                //        }
                //    },0);
                //}
            }

            AuthService.invitations(vm.model, function(response){
                vm.invitations = response.data;
                ClientService.hasInstance(vm.model, function(clientResponse){
                    vm.hasInstance=clientResponse.data;

                    vm.isLoaded = true;
                });
            });

        };

        // will bind to copy, should not modify HttpRequestInterceptor internals directly
        $scope.$on("server-response-arrived", function (event, object, status) {

            var oldStatus = vm.responseStatus;

            // safe to bind and change - status is a copy
            if (object.status !== 422) {
                // ignore form errors in global popup
                vm.responseStatus = status;
            }

            // kill success banner for GET methods,
            // but only if it is not yet left from previous POST etc. requests
            if(object.config.method === "GET"){
                if(!(oldStatus.isSucceeded)){
                    vm.responseStatus.isSucceeded = false;
                }
            }

            // define "silentSuccess: true" in your resource services to ignore success status popup
            if(object.config.silentSuccess) {
                vm.responseStatus.isSucceeded = false;
            }

            // workaround for separate file uploads - make them silent, too
            if(object.config._chunkSize || object.config._chunkSize === null) {
                vm.responseStatus.isSucceeded = false;
            }

            if(vm.responseStatus.isSucceeded) {
                // auto-close our success box, so the user does not have to do it manually
                $timeout(function () {
                    vm.closeSuccessAlert();
                }, 2000);
            }
        });

        $scope.$on("server-request-started", function (event, config, status) {
            // sync status to clear the notification
            // but leave the message intact to preserve text while animating

            // TODO: it would be nice to filter some requests
            // to prevent all messages from closing
            vm.responseStatus.isFailed = status.isFailed;

            // clear old failure status - after next request failure might be fixed
            // but leave old success status
            // so user can read it
            if(!(vm.responseStatus.isSucceeded)){
                vm.responseStatus.isSucceeded = status.isSucceeded;
            }
        });

        var safeStates = [
            "master",
            "master.terms",
            "master.register"
        ];

        // prevent going to inner states if we are not at app root
        $rootScope.$on("$stateChangeStart",
              function(event, toState, toParams, fromState, fromParams){

                  // some states are ok even in default state
                  if($window.location.pathname !== "/" &&
                          safeStates.indexOf(toState.name) == -1){
                      console.log(toState);
                       event.preventDefault();
                  }
                  // transitionTo() promise will be rejected with
                  // a 'transition prevented' error
        });

        vm.closeSuccessAlert = function () {
            // hide temporarily, so it can be displayed again in case of success
            vm.responseStatus.isSucceeded = false;
        };

        vm.switchLanguage = function(code){

            // redirect with hash to keep return address
            var newLocationWithHash
                    = "/switch-language/" + code + "?redir="
                    + encodeURIComponent($window.location.href);

            $window.location.href = newLocationWithHash;
        };

        vm.acceptCookies = function() {
          localStorageService.set("cookiesAcceptance", true);
          vm.showCookieAcceptance = false;
        };

        vm.closePopup = function() {
          $("#cookiesPopup").velocity("transition.fadeOut");
        };

        vm.subscribe = function () {
            if (FormValidatorService.validateWithFocus(vm.form)) {
                SubscribeService.subscribe({email: vm.email}, function() {
                    vm.subscribeModal = $uibModal.open({
                        animation: true,
                        templateUrl: "subscribe.html",
                        controller: 'MasterController as cntrlr',
                        size: 'sm',
                        keyboard: false
                    });

                    $timeout(function () {
                        vm.subscribeModal.dismiss();
                    }, 2000);

                    vm.email = null;
                    FormValidatorService.clearErrors(vm.form);

                }, function(error) {

                });
            }
        };
    }

    // class-like JavaScript extending without Angular heavy-weight logic
    Utilities.inherit(MasterController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);
