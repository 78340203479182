(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
            .service('AuthService',
                ['$resource', AuthService]);

    function AuthService($resource) {
        return $resource('', {}, {
            'email': {
                url:    '/api/internal/logins/password-token-requests',
                method: 'POST',
                silentSuccess: true
            },
            'reset':{
                url:    '/api/internal/logins/password-change-requests',
                method: 'POST',
                silentSuccess: true
            },
            'acceptInvitation':{
                url:    '/api/internal/logins/invitation-accept-requests',
                method: 'POST',
                silentSuccess: true
            },
            'disconnectInvitation':{
                url:    '/api/internal/logins/disconnect-invitation',
                method: 'POST',
                silentSuccess: true
            },
            'declineInvitation':{
                url:    '/api/internal/logins/decline-invitation',
                method: 'POST',
                silentSuccess: true
            },
            'invitations':{
                url:    '/api/internal/logins/invitations',
                method: 'POST',
                silentSuccess: true
            },
            'login':{
                url:    '/auth/login',
                method: 'POST',
                silentSuccess: true
            }
        });
    }

})(window.angular, window.Utilities);