(function(angular, App) {
  'use strict';

  angular.module('common.controllers', []).controller('BaseController', ['$scope', 'I18nService', BaseController]);

  /**
   * Converts sort order array to a string,
   *
   * @param {Array} sortOrder
   * @returns {String}
   */
  function normalizeSortOrder(sortOrder) {
    var normalizedSortOrder = 'id,desc';

    if (sortOrder && sortOrder.length > 0) {
      normalizedSortOrder = sortOrder[0].colId + ',' + sortOrder[0].sort;
    }

    return normalizedSortOrder;
  }

  function BaseController($scope, I18nService) {
    // store dependency links
    this.i18nService = I18nService;
    this.$scope = $scope;

    // default paginator settings
    this.gridName = '';
    this.totalRowCount = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.pageSizes = [10, 20, 50];
    this.gridOptions = {};
    this.datepickers = {};

    this.updateColumns = function(event, columnName) {
      this.columnDefs.forEach(function(col) {
        if (col.field == columnName) {
          col.hide = !col.hide;
        }
      });

      this.gridOptions.api.setColumnDefs(this.columnDefs);
      localStorage.setItem('columnDefs:' + this.gridName, JSON.stringify(this.columnDefs));
      var gridWidth = $('.ag-body').width();
      if (gridWidth > 1300) {
        this.gridOptions.api.sizeColumnsToFit();
      }
      $scope.master.updateFilterState(this.gridName, this.columnDefs);
    };

    this.loadColDefaults = function (gridInfo, createNewDataSource) {
          if (this.gridOptions.api) {
              this.gridOptions.api.setColumnDefs(gridInfo.defaultColumnDefs);
              localStorage.setItem('columnDefs:' + this.gridName, JSON.stringify(this.columnDefs));
              $scope.master.resetFilterSelect(gridInfo, createNewDataSource);
          }
      };

    this.updateFilters = function() {
      $scope.master.updateFilterState(this.gridName, this.columnDefs);
    };

    this.resizeGrid = function(gridApi) {
      var grid = gridApi;
      var gridWidth = $('.ag-body').width();
      if (gridWidth > 1300) {
        grid.sizeColumnsToFit();
      }
      $(window).resize(function() {
        var gridWidth = $('.ag-body').width();
        if (gridWidth > 1300) {
          grid.sizeColumnsToFit();
        }
      });
    };

    this.setGridHeight = function(rows, rowSize) {
      rows = this.pageSize <= rows ? this.pageSize : rows;
      var height =
        rows > 0
          ? !rowSize ? (rows < 10 ? 40 * rows + 100 : 40 * rows + 70) : rows < 10 ? rowSize + 100 : rowSize + 70
          : 200;
      return { height: height + 'px' };
    };

    this.goToNextPage = function() {
      if (this.currentPage * this.pageSize < this.totalRowCount) {
        this.currentPage++;
        this.createNewDataSource();
      }
    };

    this.goToPreviousPage = function() {
      if (this.currentPage - 1 > 0) {
        this.currentPage--;
        this.createNewDataSource();
      }
    };

    this.goToLastPage = function() {
      if (this.currentPage * this.pageSize < this.totalRowCount) {
        this.currentPage = Math.ceil(this.totalRowCount / this.pageSize);
        this.createNewDataSource();
      }
    };

    this.goToFirstPage = function() {
      if (this.currentPage > 1) {
        this.currentPage = 1;
        this.createNewDataSource();
      }
    };

    this.goToPage = function() {
      var isInRange = this.currentPage <= Math.ceil(this.totalRowCount / this.pageSize);
      if (/^[0-9]+$/.test(this.currentPage) && this.currentPage != 0 && isInRange) {
        this.createNewDataSource();
      }
    };

    this.resetFilterString = function() {
      if ($scope.master.filterString) {
        $scope.master.filterString[this.gridName] = '';
      }
    };

    var that = this;
    $scope.$watch('cntrlr.pageSize', function(newValue, oldValue) {
      if (oldValue === newValue) return;
      that.pageSize = newValue;
      that.createNewDataSource();
      that.saveTableConfiguration(null, null, newValue);
    });

    this.openDatepicker = function(datepicker) {
      this.datepickers[datepicker].opened = true;
    };

    /**
     * Global app initialization.
     *
     * @param {type} initData
     * @returns {undefined}
     */
    this.initApp = function(initData) {
      if (initData && initData.i18n) {
        this.i18nService.addLocaleTexts(initData.i18n.currentLocale, initData.i18n.texts);
        this.i18nService.setCurrentLocale(initData.i18n.currentLocale);
      }
    };

    this.getValidSortModel = function(sortModel) {
      if (sortModel.length > 0 && sortModel[0].colId == 0) {
        sortModel[0].colId = 'id';
        sortModel[0].sort = 'desc';
      }

      return sortModel;
    };

    this.getTableConfiguration = function() {
      var configuration = localStorage.getItem('grid-configuration:' + this.gridName);

      try {
        configuration = JSON.parse(configuration);

        if (configuration) {
          configuration.sortModel = this.getValidSortModel(configuration.sortModel);
        }
      } catch (error) {
        console.error(error);

        configuration = {};
      }

      return configuration || {};
    };

    this.getSortOrder = function(defaultSortOrder) {
      var configuration = this.getTableConfiguration();
      var sortModel = this.gridOptions.api ? this.gridOptions.api.getSortModel() : [];

      defaultSortOrder = defaultSortOrder || normalizeSortOrder(configuration.sortModel) || 'id,desc';

      return sortModel && sortModel.length !== 0 ? sortModel[0].colId + ',' + sortModel[0].sort : defaultSortOrder;
    };

    /**
     * Reads and parses grid configuration from the local storage, and
     * applies it to the current grid.
     *
     * @param {Object} gridOptions
     * @returns {Object}
     */
    this.loadTableConfiguration = function(gridOptions) {
      var configuration = this.getTableConfiguration();
      var that = this;

      if (configuration) {
        this.pageSize = configuration.pageSize || this.pageSize;

        gridOptions.api.setSortModel(configuration.sortModel || []);

        if (configuration.columnState) {
          var configurationColumns = [];
          var gridoptionColumns = [];

          angular.forEach(configuration.columnState, function(value, name) {
            configurationColumns.push({name : value.colId});   
          });

          angular.forEach(gridOptions.columnApi._columnController.gridColumns, function(value, name) {
            gridoptionColumns.push({name : value.colId});
          });

          var keys1 = Object.keys(configurationColumns);
          var keys2 = Object.keys(gridoptionColumns);

          var len1 = keys1.length;
          var len2 = keys2.length;
          
          function compareColumns(localstorage, database) {
              if(len1 !== len2) {
                return false;
              }

              for (var i = 0; i < len1; i++) {              
                if(localstorage[i].name !== database[i].name){
                  return false;
                }
              }
            return true;
          }
          
          if(compareColumns(configurationColumns, gridoptionColumns)){
            gridOptions.columnApi.setColumnState(configuration.columnState);
          }
        
          
        }
      }
    };

    this.ensureParamValue = function(paramName, paramValue) {
      var savedConfiguration = this.getTableConfiguration();
      var param;

      switch (paramName) {
        case 'pageSize':
          param = paramValue || this.pageSize;
          break;
        case 'columnState':
        case 'sortModel':
          if (Array.isArray(paramValue)) {
            param = paramValue;
          } else {
            param = savedConfiguration[paramName];
          }

          break;

        default:
          break;
      }

      return param;
    };

    /**
     * Saves current grid configuration to the local storage.
     */
    this.saveTableConfiguration = function(columnState, sortModel, pageSize) {
      if (this.gridName) {
        var configuration = {
          pageSize: this.ensureParamValue('pageSize', pageSize),
          sortModel: this.ensureParamValue('sortModel', sortModel),
          columnState: this.ensureParamValue('columnState', columnState)
        };

        localStorage.setItem('grid-configuration:' + this.gridName, JSON.stringify(configuration));
      }
    };
  }

  // store our master into the app so we can extend other JS controllers from it
  // without using Angular
  App.BaseController = BaseController;

  // BaseController will be inherited often,
  // and initController might need overriding in children,
  // therefore adding functions to prototypes to support correct prototype chain
  // when inheriting

  /**
   * Inherited and reusable by all controllers.
   *
   * @param {type} initData
   * @returns {undefined}
   */
  BaseController.prototype.initController = function(initData) {
    var that = this;

    this.resetFilterString();

    if (initData && initData.i18n) {
      this.i18nService.addLocaleTexts(initData.i18n.currentLocale, initData.i18n.texts);
      // do not set locale this time, it can be changed only in global app initializer
    }
    // init other modules inherited by other controllers

    if (this.gridOptions.onColumnResized) {
      var oringinalEventHandler = this.gridOptions.onColumnResized;

      this.gridOptions.onColumnResized = function(event) {
        oringinalEventHandler(event);
        that.saveTableConfiguration(that.gridOptions.columnApi.getColumnState());
      };
    } else {
      this.gridOptions.onColumnResized = function(event) {
        that.saveTableConfiguration(that.gridOptions.columnApi.getColumnState());
      };
    }

    this.gridOptions.onSortChanged = function() {
      var newSortOrder = [];

      if (that.gridOptions.api) {
        newSortOrder = that.gridOptions.api.getSortModel();
      }

      if (newSortOrder.length > 0) {
        if (newSortOrder[0].colId != 0) {
          processNewSortOrder(newSortOrder);
        }
      } else {
        processNewSortOrder(newSortOrder);
      }
    };

    function processNewSortOrder(sortModel) {
      var configuration = that.getTableConfiguration();

      that.saveTableConfiguration(null, sortModel);

      if (normalizeSortOrder(configuration.sortModel) != normalizeSortOrder(sortModel)) {
        that.createNewDataSource();
      }
    }

    this.gridOptions.onColumnMoved = function() {
      that.saveTableConfiguration(that.gridOptions.columnApi.getColumnState());
    };
  };

  /**
   *
   */
  BaseController.prototype.resetSelect = function(object, property) {
    if (typeof object == 'object' && object.hasOwnProperty(property)) {
      object[property] = null;
    }
  };
})(window.angular, (window.App = window.App || {})); // lazy create App on demand, if does not exist
