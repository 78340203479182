/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function (angular, moment) {
    "use strict";

    /**
     * Various small utility filters useful in any web site
     */
   angular.module('common.filters')
 
    // Formats grid cells, form outputs etc. 
    // according to user's current format settings.
    // Converts timezone from server (UTC presumed) to user's timezone,
    // if true is given as the third filter flag.
    // Usually you want to leave it undefined for dates
    // and set to true for datetime and time values.
    // formatKey is one of recognized formats, e.g.
    // date_format, date_time_format, time_format
    .filter('formatDatetimeWithTimezone', ['I18nService', 
        function (I18nService) {
            return function (input, formatKey, applyCurrentTimezone) {
                
                // Sanity check - ignore empties
                if(!input){
                    return input;
                }
                
                var inFormat = I18nService.getServerMomentDatetimeFormatByKey(formatKey);
                var outFormat = I18nService.getCurrentMomentDatetimeFormatByKey(formatKey);

                // assume UTC timezone by default to avoid conversion
                var tzFrom = "UTC";
                var tzTo = "UTC";

                if(applyCurrentTimezone){
                    tzFrom = I18nService.getServerTimezone();
                    tzTo = I18nService.getCurrentTimezone();
                }

                // Angular's date filter compensates for timezone
                // to forbid unexpected conversions
                // as long as our filters are conformant with Angular's specs.  
                // Although Angular's date filter is great and it even
                // compensates for timezone to forbid unexpected conversions,
                // still it will not work at all 
                // if we pass ISO8601 incompatible string, 
                // therefore we have to use moment.js together with timezone.

                // converting from / into requested format with timezones
                var formatted = moment.tz(input, inFormat, tzFrom).
                        tz(tzTo).format(outFormat);

                return formatted;
            };
    }])

    // some shorthands
   .filter('formatDatetime', ['$filter',
       function ($filter) {
        return function (input) {
            return $filter('formatDatetimeWithTimezone')(input, "date_time_format", true);
        }
    }])
   .filter('formatDate', ['$filter',
       function ($filter) {
        return function (input) {
            return $filter('formatDatetimeWithTimezone')(input, "date_format", false);
        }
    }])
    .filter('formatTime', ['$filter',
       function ($filter) {
        return function (input) {
            return $filter('formatDatetimeWithTimezone')(input, "time_format", true);
        }
    }]);


})(window.angular, window.moment);
