(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("ProfileDetailsController",
                    ["$scope", "ProfileService","$uibModal", "$location", ProfileDetailsController]);

    function ProfileDetailsController($scope, ProfileService,$uibModal, $location) {

        // must be called like this to ensure parent initialization
        ProfileDetailsController.parent.constructor.apply(this, [$scope]);

        var vm = this;
        vm.selectedInstance = 0;

        vm.entity = {};

        vm.initController = function() {
            ProfileDetailsController.parent.initController.apply(this, arguments);

            ProfileService.show(null, function(response) {
                vm.entity = response.data;
            });
        };

        vm.delete = function() {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'profile_delete_modal.html',
                controller: 'ProfileDeleteController as cntrlr',
                size: 'sm'
            });

            modalInstance.result.then(function() {
                ProfileService.delete({
                }, function() {
                    window.location.href=$location.protocol() + "://" + location.host+'/auth/logout';
                });
            });
        };

    }

    Utilities.inherit(ProfileDetailsController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);

