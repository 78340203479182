(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("LoginController",
                    ["$scope", "$timeout", "I18nService",
                        "FormValidatorService", "BindingService",
                        LoginController]);

    /*
     * Controller for log in
     */
    function LoginController($scope, $timeout, I18nService, FormValidatorService, BindingService) {

        // must be called like this to ensure parent initialization
        LoginController.parent.constructor.apply(this, 
                                                [$scope, I18nService]);

        var vm = this;
       
        // not actually doing anything in this case, left as an example for future forms
        vm.initController = function(directErrors) {
           LoginController.parent.initController.apply(this, arguments);

           FormValidatorService.assignServerErrors(directErrors);

            // to prevent typing in form while it still loading
            BindingService.safeApply($scope, function(){ 
                vm.loaded = true;
            });
        };
       
        // do not POST through ajax
        // because server will need POST data also from outer calls
        // but do some minimal validation before POSTing
        
        // should be added to ng-keyup like
        // ng-keyup="$event.keyCode == 13 && cntrlr.submitForm()"
        // if submit with ENTER is needed
        vm.submitForm = function ()
        {
            $timeout(function(){
                if(FormValidatorService.validateWithFocus(vm.form)){
                    vm.form.commit();
                }
            });
            
            return true;
        };       
    }
    
    Utilities.inherit(LoginController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);