(function ($, Utilities) {
    'use strict';
    $(document).ready(function() {
        $(".house#property-info").show();
        $('#property-info').addClass("active-item");
        $('.text-block-info#property-info').show();

        $(".feature-nav-item").on("click touchstart", function() {
            var checkClass = $(this).attr('class');
            if(checkClass.indexOf('active-item') !== -1)
            {
                return false;
            }
            $(".feature-nav-item").removeClass("active-item");
            $(this).addClass("active-item");
            var imgName = $(this).attr('id');
            $(".house").hide();
            $(".house#"+imgName).show();
            $(".text-block .text-block-info").hide();
            $(".text-block-info#"+imgName).show();

        });

        $(".house-blocks span").on("click touchstart", function() {
            var checkId = $(this).attr('id');
            $(".feature-nav-item").removeClass("active-item");
            $(".feature-nav-item#"+checkId).addClass("active-item");
            $(".house").hide();
            $(".house#"+checkId).show();
            $(".text-block .text-block-info").hide();
            $(".text-block-info#"+checkId).show();
        });

    });

})(window.jQuery, window.Utilities);