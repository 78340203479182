(function ($, Utilities) {
  'use strict';

  $(document).ready(function() {
    // For proper animations
    var lastClickTime = {
      burgerBtn: 0,
      closeMenuBtn: 0,
      languagesBtn: 0,
      profileBtn: 0
    };

    function closeMenu() {
      var seconds = new Date().getTime() / 1000;

      if ((seconds - lastClickTime.closeMenuBtn) > 1) {
        $("#full-width-menu").velocity({ left: '-100%' });

        lastClickTime.closeMenuBtn = seconds;

        $("body").css("overflow-y", "visible");
        $("html").css("overflow-y", "visible");

        setTimeout(function() {
          $("#full-width-menu").hide();
        }, 500);
      }
    }

    $(".burger").click(function() {
      var seconds = new Date().getTime() / 1000;

      if ((seconds - lastClickTime.burgerBtn) > 1) {
        $("#full-width-menu").show();
        $("#full-width-menu").velocity({ left: '0' });

        lastClickTime.burgerBtn = seconds;

        $("body").css("overflow-y", "hidden");
        $("html").css("overflow-y", "hidden");
      }

      return false;
    });

    $("#close-menu").click(function() {
      closeMenu();

      return false;
    });

    $(".close-menu-trigger").click(function() {
      setTimeout(function() {
        closeMenu();

        return false;
      }, 0);
    });

    $(".language-slidedown").click(function() {
      var $this = $(this);
      var $targetContainer = $('.languages');

      var seconds = new Date().getTime() / 1000;

      if ((seconds - lastClickTime.languagesBtn) > 0.4) {
        $this.toggleClass('open');

        if(!$targetContainer.hasClass('open')) {
          $targetContainer.css('display', 'block');
          $targetContainer.css('margin-top', -$targetContainer.height());
          $targetContainer.velocity({ marginTop: 0, marginBottom: 0 }, { duration: 350 });
          $targetContainer.addClass('open');
        }
        else {
          $targetContainer.velocity({ marginTop: -$targetContainer.height() - 60, marginBottom: 0 }, { duration: 350 });
          $targetContainer.removeClass('open');
        }

        lastClickTime.languagesBtn = seconds;
      }

      return false;
    });

    $(".domains-slidedown").click(function() {
      var $this = $(this);
      var $targetContainer = $('.domains');

      var seconds = new Date().getTime() / 1000;

      if ((seconds - lastClickTime.profileBtn) > 0.4) {
        $this.toggleClass('open');

        if(!$targetContainer.hasClass('open')) {
          $targetContainer.css('display', 'block');
          $targetContainer.css('margin-top', -$targetContainer.height());
          $targetContainer.velocity({ marginTop: 0, marginBottom: 0 }, { duration: 350 });
          $targetContainer.addClass('open');
          $(".slidedown-expand").html("&#xE5CE;");
        }
        else {
          $targetContainer.velocity({ marginTop: -$targetContainer.height() - 60, marginBottom: 0 }, { duration: 350 });
          $targetContainer.removeClass('open');
          $(".slidedown-expand").html("&#xE5CF;");
        }

        lastClickTime.profileBtn = seconds;
      }

      return false;
    });
  });

})(window.jQuery, window.Utilities);
