/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function(angular) {
  'use strict';

  angular
    .module('common.services')
    .service('HttpRequestInterceptor', [
      '$q',
      '$window',
      '$rootScope',
      '$timeout',
      'ErrorFactory',

      HttpRequestInterceptor
    ]);

  /*
     * Deals with pre/post processing AJAX requests
     * and showing global error and success messages
     */
  function HttpRequestInterceptor($q, $window, $rootScope, $timeout, ErrorFactory) {
    var that = this;

    var state = {
      isFailed: false,
      isSucceeded: false,
      failureMessage: ''
    };

    function resetStatuses() {
      state.isFailed = false;
      state.isSucceeded = false;
      state.failureMessage = '';
    }

    /**
     * Complies with Angular interceptor contract
     *
     * @param {type} config
     * @returns {unresolved}
     */
    this.request = function(config) {
      // angular sends fake requests for cached templates
      // ignore these
      if (!config.url.match(/\.html$/)) {
        // a new request has started, clear old statuses
        resetStatuses();
        notifyRequestListeners(config);
      }

      return config;
    };

    /**
     * Complies with Angular interceptor contract
     *
     * @param {type} rejection
     * @returns {unresolved}
     */
    this.responseError = function(rejection) {
      state.isFailed = true;

      console.log('Request failed', rejection);

      // network errors usually are with -1, but might be some other
      // unknown status, undefined in HTTP
      if (rejection.status < 100) {
        state.failureMessage = 'errors.network_failure';
        notifyResponseListeners(rejection);
        return $q.reject(rejection);
      }

      if (rejection.status === 422) {
        if (rejection.data.errors && rejection.data.errors.title) {
          state.failureMessage = rejection.data.errors.title;
        }

        // forms validator service will deal with this
        // in its event listener
        notifyResponseListeners(rejection);
        return $q.reject(rejection);
      }

      // on 401, reload entire page to go back to login
      if (rejection.status === 401) {
        state.failureMessage =
          rejection.data.errors && rejection.data.errors.title ? rejection.data.errors.title : null;

        var redirect = rejection.data && rejection.data.data ? rejection.data.data.redirect : null;

        // will show redirecting message

        // href to login with session timeout message
        // give user time to read the message
        $timeout(function() {
          var authExpireLogin = '/auth/login';
          if (redirect) {
            authExpireLogin = redirect;
          }

          // if have parent, redirect it
          if ($window.self !== $window.top) {
            $window.parent.location.href = authExpireLogin;
          } else {
            $window.location.href = authExpireLogin;
          }
        }, 5000);

        notifyResponseListeners(rejection);
        return $q.reject(rejection);
      }

      // KLUDGE: Handle autocomplete errors without a global error popup.
      // Bad idea, service shouldn't really care, autocomplete
      // access violation has the same severity as every other access violation
      if (rejection.status === 403 && ErrorFactory.isAutocompleteError(rejection)) {
        state.isFailed = false;

        notifyResponseListeners(rejection);

        return $q.reject(rejection);
      }

      if (rejection.status === 409) {
        // conflict of data concurrency
        // take the title from global or first error available
        state.failureMessage =
          rejection.data.errors && rejection.data.errors[0]
            ? rejection.data.errors[0].title
            : rejection.data.errors.title;

        notifyResponseListeners(rejection);

        return $q.reject(rejection);
      }

      // special case - on 503, reload entire page to show the maintenance screen
      if (rejection.status === 503) {
        // if have parent, redirect it
        if ($window.self !== $window.top) {
          $window.parent.location.href = '/';
        } else {
          $window.location.href = '/';
        }

        return $q.reject(rejection);
      }

      // all other statuses with first available message,
      // if received any
      var msg = rejection.data.errors && rejection.data.errors.title ? rejection.data.errors.title : '';

      state.failureMessage = msg;

      notifyResponseListeners(rejection);

      return $q.reject(rejection);
    };

    /**
     * Complies with Angular interceptor contract
     *
     * @param {type} response
     * @returns {unresolved}
     */
    this.response = function(response) {
      // angular sends fake requests for cached templates
      // ignore these
      if (!response.config.url.match(/\.html$/)) {
        // forget old status also when done
        // to avoid keeping failures if a new response succeeds
        // because request start is not enough -
        // responses might come in different order
        resetStatuses();

        state.isSucceeded = true;
        notifyResponseListeners(response);
      }

      return response;
    };

    // shout at every scope out there
    function notifyResponseListeners(responseObj) {
      $rootScope.$broadcast(
        'server-response-arrived',
        responseObj,
        // won't let others bind and corrupt the state
        angular.copy(state)
      );
    }

    function notifyRequestListeners(config) {
      $rootScope.$broadcast(
        'server-request-started',
        config,
        // won't let others bind and corrupt the state
        angular.copy(state)
      );
    }
  }
})(window.angular);
