(function(angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
        .controller("SendFormController", ["$scope", "$timeout", "I18nService", "$uibModalInstance", "FormValidatorService", "blockUI", "SendFormService", "PhysicalPersonRegistrationService",
            SendFormController
        ]);

    function SendFormController($scope, $timeout, I18nService, $uibModalInstance, FormValidatorService, blockUI, SendFormService, PhysicalPersonRegistrationService) {


        SendFormController.parent.constructor.apply(this, [$scope, I18nService]);

        var vm = this;

        vm.model = {};
        vm.created=false;
        var registerBlock = blockUI.instances.get('registerModalBlock');

        vm.sendForm = function() {
            registerBlock.start();
            if (FormValidatorService.validateWithFocus(vm.register)) {
                SendFormService.sendForm(vm.model, function() {
                    registerBlock.stop();
                    $uibModalInstance.close();

                    vm.model = {};
                    FormValidatorService.clearErrors(vm.register);
                }, function(error) {
                    registerBlock.stop();
                });
            } else {
                registerBlock.stop();
            }
        };

        vm.sendRegistrationForm = function() {
            registerBlock.start();
            if (FormValidatorService.validateWithFocus(vm.registerUser)) {
                PhysicalPersonRegistrationService.save(vm.model, function() {
                    registerBlock.stop();
                    vm.created=true;
                    //$uibModalInstance.close();

                    vm.model = {};
                    FormValidatorService.clearErrors(vm.registerUser);
                }, function(error) {
                    registerBlock.stop();
                });
            } else {
                registerBlock.stop();
            }
        };
    }

    Utilities.inherit(SendFormController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);