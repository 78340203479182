(function(angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
        .controller("TimerController", ["$scope", "$interval", "blockUI", TimerController]);

    function TimerController($scope, $interval, blockUI) {

        // must be called like this to ensure parent initialization
        TimerController.parent.constructor.apply(this, [$scope]);

        var vm = this;

        var timerReady = false;
        var timerBlock = blockUI.instances.get('timer');

        timerBlock.start();

        $interval(function() {
            var start = moment('2016-04-01', 'YYYY-MM-DD');
            var now = moment();

            var month = now.diff(start, 'month');
            start.month(start.month() + month);

            var days = now.diff(start, 'days');
            start.days(start.days() + days);

            var hours = now.diff(start, 'hours');
            start.hours(start.hours() + hours);

            var minutes = now.diff(start, 'minutes');

            vm.month = month;
            vm.days = days;
            vm.hours = hours;
            vm.minutes = minutes;

            if (!timerReady) {
                timerBlock.stop();
                timerReady = true;
            }
        }, 1000);
    }

    Utilities.inherit(TimerController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);
