(function(angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
        .controller("ProfileDeleteController", ["$scope", "I18nService", "$uibModalInstance",
            ProfileDeleteController
        ]);

    function ProfileDeleteController($scope, I18nService, $uibModalInstance) {


        ProfileDeleteController.parent.constructor.apply(this, [$scope, I18nService]);

        var vm = this;

        vm.ok = function() {
            $uibModalInstance.close();
        };
    }

    Utilities.inherit(ProfileDeleteController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);