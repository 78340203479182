/**
 * Some time proven JS utilities
 *
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function (window, document, angular) {
    'use strict';

    // keep a global instance, independent of Angular
    var utilities = {};

    /**
     * Classlike inheritance to ensure correct new() functionality.
     * Use like
     * Utilities.inherit(ChildClass, BaseClass);
     *
     * Parent function can be called as
     * MyClass.parent.funcName.apply(this, arguments);
     *
     * Parent cosntructor can be called as
     * MyClass.parent.constructor.apply(this, arguments);
     *
     * @param {function} childClass
     * Child constructor function.
     *
     * @param {function} parentClass
     * Parent constructor function.
     *
     */
    utilities.inherit = function (childClass, parentClass) {
        // we don't just set prototype of parent to new parent() because:
        // -  new parent() - the constructor may strictly require arguments and die without them
        // - conceptual issue - we don’t want to create a new parent instance, we just want to inherit from it

        // prototype needed because of functions - prototype should point to object and not function
        childClass.prototype = Object.create(parentClass.prototype);

        // and some helpers to call "superclasses" correctly
        childClass.prototype.constructor = childClass;
        childClass.parent = parentClass.prototype; // blame functions again

        // will have to call like
        // MyClass.parent.constructor.apply(this, arguments);
        // as there's no easier way around to access the right "this"
    };

    // loads a script syncrhonouslsy and adds to the document to execute it
    utilities.loadScript = function (path) {
        var js = window.document.createElement("script");
        js.type = "text/javascript";
        js.src = path;
        window.document.body.appendChild(js);
    };


    /**
     * Utility function to transform object
     * like
     * { x1:value1, .. , xN:valueN }
     * to array
     * [{val:x1, label:value1}, .. , {val:xN, label:valueN}]
     *
     * @param {object} obj
     * { x1:value1, .. , xN:valueN }
     *
     * @param {string} keyName
     * Name to use as name of "key" property in the resulting objects.
     *
     * @param {string} valueName
     * Name to use as name of "value" property in the resulting objects.
     *
     * @returns {Array}
     * [{val:x1, label:value1}, .. , {val:xN, label:valueN}]
     */
    utilities.keyValueObjectToObjectArray = function (obj, keyName, valueName) {
        var arr = [];
        angular.forEach(obj,
                function (value, key) {

                    var arrItem = {};
                    // array accessors for string names
                    arrItem[keyName] = key;
                    arrItem[valueName] = value;

                    // protect against property reordering by some browsers
                    if (key === "") {
                        this.unshift(arrItem);
                    }
                    else {
                        this.push(arrItem);
                    }
                }, arr);

        return arr;
    };

    /**
     * Validates each file object to satisfy requirements
     * and also validates total size of upload and number of files.
     * Each invalid file object is marked with invalidSize=true or invalidSize=true,
     * depending on situation.
     *
     * @param {array[]} files
     * Array of JS File objects to validate
     *
     * @param {string} extensions
     * Comma separated list of allowed extensions, e.g.
     * .csv,.xls,.doc
     *
     * @param {int} countLimit
     * Max number of files in array.
     *
     * @param {int} sizeLimit
     * Max size of each individual file.
     *
     * @param {int} totalSizeLimit
     * Max size of upload (sum of all file sizes)
     *
     * @returns {Boolean}
     * Whether all the checks passed.
     */
    utilities.validateFiles = function(files, extensions, countLimit, sizeLimit, totalSizeLimit){
        var validFileExtensions = extensions.split(",");

        var bvalid = true;
        var totalSize = 0;

        for (var f = 0; f < files.length; f++) {

            var hasValidExt = false;
            var sFileName = files[f].name;
            var iFileSize = files[f].size;

            if(iFileSize > sizeLimit ||
                  iFileSize > totalSizeLimit){
               // fail globally
               bvalid = false;
               files[f].invalidSize = true;
            }

            totalSize += iFileSize;

            for (var j = 0; j < validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];

                if (sFileName.substr(sFileName.length - sCurExtension.length,
                    sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
                    hasValidExt = true;
                    break; // found it
                }
            }

            if (!hasValidExt) {
                files[f].invalidType = true;
                // fail globally, have invalid extension for one of files
                bvalid = false;
            }
        }

        if(totalSize > totalSizeLimit){
             // fail globally
             bvalid = false;
        }

        if(files.length > countLimit){
            // fail globally
            bvalid = false;
        }

        // final result
        return bvalid;
    };

    /**
     *
     * Safe regex match extracter
     * https://gist.github.com/slevithan/3444018
     */
    utilities.getMatches = function (str, regex) {
        var matches = [];
        var match;

        if (regex.global) {
            regex.lastIndex = 0;
        } else {
            regex = new RegExp(regex.source, 'g' +
                (regex.ignoreCase ? 'i' : '') +
                (regex.multiline ? 'm' : '') +
                (regex.sticky ? 'y' : ''));
        }

        while (match = regex.exec(str)) {
            // If you want to use regex.lastIndex in this loop, you'd need more
            // code here to fix IE < 9

            matches.push(match);

            if (regex.lastIndex === match.index) {
                regex.lastIndex++;
            }
        }

        return matches;
    };

    // функция для определения кода страны и номера телефона
    // возможные варианты:
    //      in: 37128349796   out: {phone: 37128349796}
    //      in: 371 28349796  out: {phone: 28349796, countryIso2: 'lv', dialCode: 371}
    //
    utilities.splitPhone = function (phone, codesCountriesList) {
        var result = {
            phone: phone // assume default is the same given value
        };

        if(!phone){
            return result; // empty phone, return undefined
        }

        var splitIx = phone.indexOf(" ");

        result.phone = phone;

        if(splitIx === -1){
            return result; // no splitter - return supplied number as  is
        }

        var dialCode = phone.substr(0,splitIx);

        if(dialCode[0] === '+'){
            dialCode = dialCode.substr(1);
        }

        var phoneNumber = phone.substr(splitIx+1);

        var matches = codesCountriesList.filter(function(item){
                return item.dialCode === dialCode;
            });

        if(matches.length === 0){
            return result;  // no matching phone country - return supplied number as  is
        }

        result.dialCode = dialCode;
        result.countryIso2 = matches[0].iso2;
        result.phone = phoneNumber;

        return result;
    };

    /**
     * Generates random integer in bounds.
     *
     * @param {int} min
     * @param {int} max
     * @returns {int}
     */
    utilities.getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    };

    /**
     *
     * @param {type} length
     * @returns {String|Function|string_L19.String}Generates random string with given length.
     */
    utilities.makeRandomString = function (length){
        var text = "";
        // spaces more often to avoid long words
        var possible = "ABC DEF GH IJK LMN OPQRS TU VWXY Zab cde fghi jkl mno pqrst uvwx yz 0123 4567 89";

        for(var i=0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };

    /**
     * Функция создания дней месяцев
     *
     * @returns {Array}
     *
     */
    utilities.createDaysOfMonths = function () {
             var days = [];

             for(var i = 1; i < 32; i++){
                var obj = {};

                obj.id = i;
                obj.name = i;
                days.push(obj);
             }

              return days;
    }

    /**
     * Filters agGrid rows that have valid data.
     *
     * @param {Object} api
     * @param {String} [fieldName]
     * @returns {Array<Object>}
     */
    utilities.getDataRows = function (api, fieldName) {
        var rows = [];

        fieldName = fieldName || 'id';

        api.forEachNode(function(node) {
            if (node.data[fieldName]) rows.push(node);
        });

        return rows;
    }

    /**
     * Collection of various bits of information about current browser
     */
 // Opera 8.0+
  var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';
  // At least Safari 3+: "[object HTMLElementConstructor]"
  var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1+
  var isChrome = !!window.chrome && !!window.chrome.webstore;
  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;

    utilities.browserInfo = {
        isOpera : isOpera,
        isFirefox : isFirefox,
        isSafari : isSafari,
        isIE : isIE,
        isEdge : isEdge,
        isChrome : isChrome,
        isBlink : isBlink
    }

    // keep a global instance, accessible independently of Angular
    window.Utilities = utilities;

})(window, document, window.angular);
