(function (angular) {
    "use strict";

    angular.module("amber")
        // support modal windows as navigatable ui-router states
        .provider('modalState', ["$stateProvider", function ($stateProvider) {
            var provider = this;
            this.$get = function () {
                return provider;
            };
            this.state = function (stateName, options, bindToScopeContainerSelector) {
                var modalInstance;
                $stateProvider.state(stateName, {
                    url: options.url,
                    onEnter: ["$uibModal", "$state", "$timeout",
                        function ($uibModal, $state, $timeout) {

                        // break out of current exectuion flow
                        $timeout(function () {
                            if (bindToScopeContainerSelector) {
                                // a hacky solution to get our modal popup into required existing scope
                                // but should be good as long as we keep it hidden in our modalState service
                                options.scope =
                                        angular.element(bindToScopeContainerSelector).scope();
                                
                                // in case the scope has scrollables, refresh them
                                // after popup has opened fully
                                $timeout(function () {
                                    options.scope.$broadcast("content.changed");
                                    options.scope.$broadcast("content.reload");
                                }, 200);
                            }
                            
                            modalInstance = $uibModal.open(options);

                            // change route after modal result
                            modalInstance.result.then(function () {
                                // change route after clicking OK button
                                $state.go("^");
                            }, function () {
                                // change route after clicking Cancel button or clicking background
                                $state.go("^");
                            });
                        });
                    }],
                    onExit: function () {

                        if (modalInstance) {
                            modalInstance.close();
                        }
                    }
                });
            };
        }])
        .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", "modalStateProvider",
            function ($stateProvider, $urlRouterProvider, $locationProvider, modalStateProvider) {
                /*
                $locationProvider.html5Mode({
                    enabled: false,
                    requireBase: false,
                    rewriteLinks: false
                });
                    */
                       
                // no default route - we have reg and login pages with clear urls
                
                $stateProvider
                        .state("instances", {
                            url: "/instances",
                            views: {
                                "page": {
                                    templateUrl: "instances.html",
                                    controller: "InstancesController as cntrlr"
                                }
                            }
                        })
                    .state('clientPortal', {
                        url: '/client-portal',
                        views: {
                            'page@': {
                                templateUrl: 'client_portal.html',
                                controller: 'ClientPortalController as cntrlr'
                            }
                        }
                    })
                        // required for modals to be closed to parent
                        .state("master", {
                            url: ""
                        });


                modalStateProvider.state("master.terms", {
                    url: "^/terms-and-policies",
                    templateUrl: "terms-and-policies.html",
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static'
                }, "#terms-container");

                modalStateProvider.state("master.register", {
                    url: "^/register",
                    templateUrl: "register-modal.html",
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static',
                    windowClass: 'register-modal',
                    controller: 'SendFormController as cntrlr'
                });

                modalStateProvider.state("master.register-physical", {
                    url: "^/register-physical",
                    templateUrl: "register-physical-modal.html",
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static',
                    windowClass: 'register-modal',
                    controller: 'SendFormController as cntrlr'
                });

    }]);

})(window.angular); 
