(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("ClientPortalController",
                    ["$scope", "ClientService", ClientPortalController]);

    /*
     * Controller for displaying instances
     */
    function ClientPortalController($scope, ClientService) {

        // must be called like this to ensure parent initialization
        ClientPortalController.parent.constructor.apply(this, [$scope]);

        var vm = this;
        vm.selectedInstance = 0;

        vm.entity = {};

        vm.initController = function() {
            ClientPortalController.parent.initController.apply(this, arguments);

            ClientService.clientData(null, function(response) {
                vm.entity = response.data;
            });
        };

    }

    Utilities.inherit(ClientPortalController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);

