/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 *
 * Full jQuery is required!
 *
 Features:

 - single select and multi select mode with overrides for immediate apply or apply with button or ENTER.
 - search (invisible) by entering text - no filtering, only focusing or selecting.
 First invalid character will reset current search string.
 - escape will close the dropdown
 - enter or spacebar will toggle dropdown open/closed
 - spacebar in multi select mode will check / unchek currently focused item in the list
 - TAB or click outside will close the dropdown
 - supports ng-focus and ng-blur events
 - supports binding model to a property instead of entire object
 - supports dynamic source and model sync
 - supports keyboard navigation through items in both open and closed modes

 Shortcut keys will be used if dropdown is open and multiselect:
 Ctrl-A for select all, Shift-Ctrl-A for select none, Enter for Apply
 and corresponing Bootstrap tooltips will be shown on buttons with 500ms delay
 to avoid disturbing experienced users.

 While typing text, the follwoing will occur to mimic default <select>:
 - if dropdown is closed and single-select, first matching item will be selected
 - if dropdown is closed and multi select - nothing
 - if dropdown is open and single-select, first matching item will be selected
 - if dropdown is open and multi select, first matching item will be focused, but not selected

 In multiselect mode item selection is by click or spacebar (as standard checkboxes).

 In multiselect mode different item template is used (with fake checkboxes) for items
 but you can override it as usual with <msi-select-item></msi-select-item>

 Angular events emitted:
 "msi-select-applied" on pushing current changes to Angular model
 "msi-select-left" on blur
 "msi-select-escaped" on escape

 Also triggers HTML "change" event on pushing current changes to Angular model.

 Usage:
 <msi-select class="form-control"
                name="formInputName" id="controlWrapper"
                required
                ng-model="cntrlr.someModelValue"
                source-array="cntrlr.sourceArray"
                placeholder="'Select something'"
                tabindex="4"
                autofocus="autofocus"
                ng-focus=""
                ng-blur=""
                column-count="2"
                max-pill-count="1"
                item-height="10"
                search-by-property="name"
                tooltip-property="name"
                bind-model-to-property="propname"
                append-to-selector="#elemId"
                apply-immediately="true|false">

Name is mandatory.

Id is optional but highly recommended. It will be also passed to the underlying <button> control as "id=outerId+MsiSelect".
It also will be used for focus-redirect to ensure that this custom control focusing will work correctly
on validation errors (see code of formValidatorService for focusing logic). If Id is missing, Name will be used instead.

Also can use disabled, ng-disabled (for complex bindings), required, ng-required (for complex bindings)
and control-tabindex, which again will be passed to the underlying <button> control as "tabindex".

Bind-model-to-property is optional. It is static, not bindable. It must be a string name of a property with unique values inside each object of source-array.
This means that it is possible to bind ng-model to a single value and not entire object (as it is in ui-select).

Search-by-property is optional. It is static, not bindable. It must be a string name of a property which exists inside each object of source-array.
This property will be used as a value to select item by searching it using keyboard input, similarly to HTML <select>
By default will use check for properties named "name", "label" and "title".

Tooltip-property is optional. It is static, not bindable. It must be a string name of a property which exists inside each object of source-array.
This property will be used as a value to show in tooltip if you know that the text might be cropped.

Tabindex is optional. It will be removed from outer container and used as tabindex on the inner button to ensure correct TAB navigation.

Append-to-selector="#elemId" is optional. It will help to deal with cropping issues. It will cause the element during opened state
 to be ripped out of normal flow and moved to become last child of the first element which matching Append-to selector value.
 Notice that new position will be calculated as offset relative to the new parent, but it might fail.
 This might cause issues if selector is something else not body, and this new parent is scrolled.
 This might break scrolling - element will appear to be stuck.

 Autofocus is optional. It will be removed from outer container and used as autofocus on the inner button.
 It must be passed strictly with some value assigned to it, e.g. autofocus="autofocus". Ampty autofocus will not work.

 Apply-immediately is optional. It is static bool value. By default true for single select and false for multiselect.
 If set to true, then changes will be applied immediately during select or when closing the dropdown.
 If set to false, footer template with Apply and Select all and Select none buttons will be visible, if footer template is not overriden
 and user will have to click Apply or press Enter to push changes to the model.
 If user excapes or tabs out, changes will be lost

 Column-count is optional. Default value 1. Will split the list into n columns,
 providing vertical & horizontal keyboard navigation.
 Notice that in some occasions column count will be reduced by 1 -
 if there are too few items to fill all columns.

 Max-pill-count is optional. If specified, then after multiselect reaches specified limit,
 it displays "X selected" instead of pills.

 Ng-focus and ng-blur are optional. They will be removed from outer container and used on inner button to ensure correct focus/blur processing.

 Placeholder is optional, it can be a string or a bound variable.
 Optional templates:
 - If msi-select-item does not exist, default will be used, which just outputs "title" or "label" property of the object.
 - If msi-select-selected does not exist, msi-select-item will be used instead.
 - msi-select-footer by default is visible only when Apply-immediately is explicitly or implicitly false

 <msi-select-item>
 <div class="msi-select-item"><span>@{{$item.icon}}</span> <span>@{{$item.title}}</span></div>
 </msi-select-item>
 <msi-select-selected>
 <div class="msi-select-item-selected"><span>@{{$selectedItem.icon}}</span> <span>@{{$selectedItem.title}}</span></div>
 </msi-select-selected>
 <msi-select-footer>
 <div class="msi-select-footer ng-hide" ng-show="!applyImmediately">
 <button type="button" class="btn" uib-tooltip="CTRL-A" tooltip-popup-delay="500" ng-click="selectAllClicked()" ng-disabled="selectedItems.length === sourceArray.length"><span>{{texts.selectAll}}</span></button>
 <button type="button" class="btn" uib-tooltip="CTRL-SHIFT-A" tooltip-popup-delay="500" ng-click="selectNoneClicked()" ng-disabled="selectedItems.length === 0"><span>{{texts.selectNone}}</span></button>
 <button type="button" class="btn btn-primary msi-select-btn-apply" uib-tooltip="ENTER"  tooltip-popup-delay="500" ng-click="applyClicked()"><span>{{texts.apply}}</span></button>
 </div>
 </msi-select-footer>
 </msi-select>

 Important about ng-if: we can't stop Angular from compiling HTML while it is inside templates,
 therefore ng-if elements will be dropped out too early. To work around this,
 use ng-late-compile-if
 which will be used to prevent early compilation while template was transcluded.

 */

(function (angular) {
    "use strict";

    angular.module("common.directives")
            .directive("msiSelect",
                    ["$sce", "$timeout", "$templateCache", "$compile", "$interpolate", "$rootScope", "msiSelectConfig",
                        MsiSelect])
            .directive("msiMultiSelect",
                    ["$sce", "$timeout", "$templateCache", "$compile", "$interpolate", "$rootScope", "msiSelectConfig",
                        MsiSelect])
            .run(["$templateCache", Templater])
            .provider("msiSelectConfig", [ ConfigProvider]);

    function Templater($templateCache) {

                    // WARNING! Javascript code relies upon specific order of elements!
                    // Do not change this template too much.

                    // span - because selects might be used inline
                    // and button - to support default focusing with TAB

                    // templates might contain DIVs, so this might be invalid HTML markup,
                    // but no other solutions for now...
                    var defaultTmpl = '<div class="msi-select-cntnr" ng-attr-uib-tooltip="{{tooltipProperty ? getTooltip() : undefined}}"' +
                            ' ng-attr-tooltip-placement="{{tooltipPlacement}}">' +
                            // main button with conditional attributes from outside
                            '<button class="msi-select-main-btn" type="button"' +
                            ' ng-attr-id="{{controlId ? controlId : undefined }}"' +
                            // disabled might change dynamically
                            // but id and tabindex should not
                            ' ng-disabled="isDisabled || undefined"' +
                            ' ng-attr-tabindex="{{controlTabIndex ? controlTabIndex : undefined}}"' +
                            ' ng-attr-autofocus="{{controlAutofocus ? controlAutofocus : undefined}}"' +
                            ' ng-focus="onFocus($event)"' +
                            ' ng-blur="onBlur($event)"' +
                            ' ng-click="isDisabled || mainBtnClicked($event)">' +
                            '<span class="msi-select-placeholder ng-hide" ng-show="appliedItems.length === 0" ng-bind="placeholder"></span>' +
                            '<span class="msi-select-caret"></span>' +
                            '</button>' +
                            /*
                             items might need clickability, but nested in button dows not let clicking
                             therefore overlaying the button with value, and will manage the size from JS
                             */
                            '<div ng-disabled="isDisabled || undefined" class="msi-selected-lst-cntnr ng-hide"' +
                            ' ng-click="isDisabled || selectCntnrClicked($event)"' + // to redirect clicks on container to the button
                            ' ng-show="appliedItems.length > 0 && appliedItems.length <= maxPillCount"></div>' +

                            // for limiter
                            '<div class="msi-selected-lst-limit ng-hide"' +
                            ' ng-click="isDisabled || selectCntnrClicked($event)"' + // to redirect clicks on container to the button
                            ' ng-show="appliedItems.length > maxPillCount"><span>{{appliedItems.length}}</span> <span>{{texts.selectedItems}}</span></div>' +

                            '<div class="msi-select-dropdown ng-hide" ng-show="isDropdownOpen">' +
                            '<div class="msi-select-col-cntnr">' +
                            '<div class="msi-select-list-cntnr">' +
                            '<ul class="msi-select-itm-list"></ul>' +
                            '</div>' +
                            '</div>' +
                            '</div>' +
                            // end with inner templates
                            '<div class="ng-hide" ng-transclude></div>' +
                            '</div>';

                    // $list and $parent.$index will be resolved to sourceMatrix later, dynamically
                    var defaultItemRepeaterTmpl = '<li ng-repeat="$item in $list track by $index"' +
                            ' ng-class="{ selected : isItemSelected($item), focused: focusedItem === $item }"' +
                            ' ng-click="isDisabled || itemClicked($item)"></li>';

                    var defaultPillRepeaterTmpl = '<span class="msi-selected-itm-cntnr" ng-repeat="$selectedItem in appliedItems track by $index"></span>';

                    var defaultItemTmpl = '<div class="msi-select-item">{{$item.name || $item.title || $item.label}}</div>';

                    var defaultMultiItemTmpl = '<div class="msi-select-item">' +
                            '<div class="msi-select-check-label">{{$item.name || $item.title || $item.label}}</div>' +
                            '<i class="fa msi-select-check"></i>' +
                            '</div>';

                    var defaultCloserTemplate = '<span class="msi-select-item-x" ng-disabled="isDisabled || undefined" ng-click="isDisabled || removeItemClicked($selectedItem, $event)">&#10006;</span>';

                    var defaultFooterTmpl = '<div class="msi-select-footer ng-hide" ng-show="!applyImmediately">' +
                            '<button type="button" class="btn" uib-tooltip="CTRL-A" tooltip-popup-delay="500" ng-click="selectAllClicked()" ng-disabled="selectedItems.length === sourceArray.length"><span>{{texts.selectAll}}</span></button>' +
                            '<button type="button" class="btn" uib-tooltip="CTRL-SHIFT-A" tooltip-popup-delay="500" ng-click="selectNoneClicked()" ng-disabled="selectedItems.length === 0"><span>{{texts.selectNone}}</span></button>' +
                            '<button type="button" class="btn btn-primary msi-select-btn-apply" uib-tooltip="ENTER"  tooltip-popup-delay="500" ng-click="applyClicked()"><span>{{texts.apply}}</span></button>' +
                            '</div>';

                    $templateCache.put("msi-select.html", defaultTmpl);
                    $templateCache.put("msi-select-item.html", defaultItemTmpl);
                    $templateCache.put("msi-multi-select-item.html", defaultMultiItemTmpl);

                    $templateCache.put("msi-select-item-repeater.html", defaultItemRepeaterTmpl);
                    $templateCache.put("msi-select-pill-repeater.html", defaultPillRepeaterTmpl);

                    $templateCache.put("msi-select-footer.html", defaultFooterTmpl);
                    $templateCache.put("msi-select-x.html", defaultCloserTemplate);
                }

    function ConfigProvider() {
        var _texts = {
            selectAll: "Select all",
            selectNone: "Select none",
            apply: "Apply",
            selectedItems: "selected"
        };

        this.texts = function (texts) {
            if (texts) {
                _texts = texts;
            }
            return _texts;
        };

        this.$get = function () {
            return {
                // return functions because we need to set up texts later in init()
                // but "interaction with providers is disallowed" in later phases
                texts: this.texts
            };
        };
    }

    function MsiSelect($sce, $timeout, $templateCache, $compile, $interpolate, $rootScope, msiSelectConfig) {

        return {
            restrict: "AE",
            require: "ngModel",
            scope:
                    {
                        // model - will be updated with full selected object
                        ngModel: "=",
                        // always an array of objects, will be tracked by $index internally
                        sourceArray: "=",
                        // settings based on attributes which might be bound
                        isRequired: "=?ngRequired",
                        // attributes which should be passed on to the real input element
                        // binding with disabled is not possible, see Angular docs
                        // using ng-disabled for binding
                        isDisabled: "=?ngDisabled",
                        placeholder: "=?"
                    },
            templateUrl: "msi-select.html",
            transclude: true, // would be great to use transclude slots, but they are not available in current stable angular

            link: function ($scope, element, attrs, ngModelCtrl) {

                var itemTemplate = element.find("msi-select-item").html();
                var selectedTemplate = element.find("msi-select-selected").html();
                var footerTemplate = element.find("msi-select-footer").html();

                var defaultItemTmpl = $templateCache.get("msi-select-item.html");
                var defaultItemRepeaterTmpl = $templateCache.get("msi-select-item-repeater.html");
                var defaultPillRepeaterTmpl = $templateCache.get("msi-select-pill-repeater.html");
                var defaultFooterTmpl = $templateCache.get("msi-select-footer.html");
                var defaultMultiItemTmpl = $templateCache.get("msi-multi-select-item.html");
                var defaultCloserTemplate = $templateCache.get("msi-select-x.html");

                // some things cached for faster access
                var domListItemsCache = [];

                $scope.columnCount = 1;

                $scope.texts = msiSelectConfig.texts();

                $scope.isMultiSelectMode = element.is("msi-multi-select") ||
                        attrs.msiMultiSelect !== undefined;

                $scope.columnCount = parseInt(attrs.columnCount, 10) || 1;
                $scope.maxPillCount = parseInt(attrs.maxPillCount, 10) || 9007199254740991; // MAX_SAFE_INTEGER

                // console.log("$scope.columnCount", $scope.columnCount);

                // if immediate flag is enforced on multimode
                // or if we have single mode and immediate flag is not enforced to be false
                $scope.applyImmediately = ($scope.isMultiSelectMode && attrs.applyImmediately === "true") ||
                        (!$scope.isMultiSelectMode && attrs.applyImmediately !== "false");

                // // console.log("applyImmediately", attrs.applyImmediately, $scope.applyImmediately,
                // ($scope.isMultiSelectMode && attrs.applyImmediately === "true"),
                // (!$scope.isMultiSelectMode && attrs.applyImmediately !== "false")
                // );

                // attributes which should have changed names to avoid breaking stuff
                // use id if exists, fallback to mandatory name in other case
                $scope.controlId = attrs.id ? (attrs.id + "MsiSelect") :
                        (attrs.name + "MsiSelect");

                $scope.appendToSelector = attrs.appendToSelector;

                $scope.controlTabIndex = attrs.tabindex;
                $scope.controlAutofocus = attrs.autofocus;
                $scope.tooltipPlacement = attrs.tooltipPlacement;
                $scope.tooltipProperty = attrs.tooltipProperty;

                // optional focus and blur - as strings here, should be evalued by angular
                // inside the template
                $scope.ngFocusExpression = attrs.ngFocus;
                $scope.ngBlurExpression = attrs.ngBlur;
                $scope.searchByProperty = attrs.searchByProperty;
                $scope.isDropdownOpen = false;

                // optional
                $scope.bindModelToProperty = attrs.bindModelToProperty;

                // special vars, used from outside in templates
                // selectedItems always holds refs to objects in sourceArray
                $scope.selectedItems = [];


                // in immediateApply mode, appliedItems will match selectedItems
                // else will differ
                $scope.appliedItems = [];
                $scope.selectedIndexes = [];

                $scope.scrollOffset = {x: 0, y: 0};

                // some attributes might be not bound but present as simple values
                // so we process them to find if they should be set
                if (typeof $scope.isRequired === "undefined") {
                    // even empty 'required' should give 'true'
                    $scope.isRequired = !!attrs.required;
                }

                if (typeof $scope.isDisabled === "undefined") {
                    //  empty 'disabled' gives undefined,
                    //  but must be true always when not undefined
                    if (typeof attrs.disabled !== "undefined") {
                        $scope.isDisabled = true;
                        // binding to "isDisabled" should do the rest
                    }
                }

                if (typeof $scope.placeholder === "undefined") {
                    // if not bound, treat as string
                    $scope.placeholder = attrs.placeholder;
                }

                // cache most often used objects
                // need full jQuery to work
                var inputButtonObj = angular.element(element.find("button[ng-attr-id]")[0]);
                var dropdownContainerObj = angular.element(element.find(".msi-select-dropdown")[0]);
                var columnContainerObj = angular.element(element.find(".msi-select-col-cntnr")[0]);

                // first containers will be used just as templates which will be duplicated later
                var itemListObj = angular.element(element.find(".msi-select-itm-list")[0]);
                var itemListContainerObj = angular.element(element.find(".msi-select-list-cntnr")[0]);

                // for yet unknown reason, have to init scrollbar here in link and not in the template itself
                // because content gets stripped out then
                var ngScroller = null;
                try {
                    ngScroller = angular.module("ngScrollable");
                } catch (err) { /* failed to require */
                }

                if (ngScroller) {
                    columnContainerObj
                            .addClass("ng-scrollable")
                            .attr("ng-scrollable",
                                    "{useKeyboard:false, useObserver:false, updateOnResize:false}")
                            .attr("spy-y", "scrollOffset.y")
                            .attr("spy-x", "scrollOffset.x");
                    // prevent up/down arrow scrolling - we handle it by item height
                }

                // add our interaction with custom formValidationService
                // to support correct focusing on errors
                element.attr("focus-redirect", "#" + $scope.controlId);
                element.addClass("loaded");

                if ($scope.controlTabIndex) {
                    // remove from element itself, as tabindex will be assigned to the button inside
                    element.removeAttr("tabindex");
                }

                if ($scope.controlAutofocus) {
                    // remove from element itself, as tabindex will be assigned to the button inside
                    element.removeAttr("autofocus");
                }

                if ($scope.ngFocusExpression) {
                    // remove from element itself, as ng-focus will be assigned to the button inside
                    element.removeAttr("ng-focus");
                }

                if ($scope.ngBlurExpression) {
                    // remove from element itself, as ng-blur will be assigned to the button inside
                    element.removeAttr("ng-blur");
                }

                // init custom teplates - rely upon the fact
                // that they will come transcluded inside last DIV
                var itemTemplate = element.find("msi-select-item").html();
                var selectedTemplate = element.find("msi-select-selected").html();
                var footerTemplate = element.find("msi-select-footer").html();
                // missing templates will be undefined

                //// console.log("initial", element.html(), itemTemplate,selectedTemplate, footerTemplate);

                if (!itemTemplate) {
                    // assume sane default
                    itemTemplate = defaultItemTmpl;
                }

                // selected template is optional
                if (!selectedTemplate) {
                    selectedTemplate = itemTemplate.replace(/\$item/gi, "$selectedItem")
                            .replace("msi-select-item", "msi-select-item-selected");
                }

                if (!footerTemplate) {
                    // assume sane default
                    footerTemplate = defaultFooterTmpl;
                }

                // special item template for multi mode, if not overriden
                if (itemTemplate === defaultItemTmpl && $scope.isMultiSelectMode) {
                    itemTemplate = defaultMultiItemTmpl;
                }

                // process late-compile- which was being used to prevent early compilation while template was transcluded
                itemTemplate = itemTemplate.replace(/ng\-late\-compile\-/gi, "ng-");
                selectedTemplate = selectedTemplate.replace(/ng\-late\-compile\-/gi, "ng-");
                footerTemplate = footerTemplate.replace(/ng\-late\-compile\-/gi, "ng-");

                //// console.log("resolved", itemTemplate,selectedTemplate, footerTemplate);

                // attach all templates to our HTML and recompile it

                // column view - build dynamically later, to avoid early compile
                itemListContainerObj.attr("ng-repeat",
                        "$list in sourceMatrix track by $index");

                // combine item template with repeater
                var repeaterTmpl = angular.element(defaultItemRepeaterTmpl);
                repeaterTmpl.append(itemTemplate);

                // insert repeater with template
                itemListObj.append(repeaterTmpl);

                // inject closer button, if needed
                if ($scope.isMultiSelectMode) {
                    selectedTemplate = $(selectedTemplate)
                            .append(defaultCloserTemplate);
                }

                // selected item(s) template in pills
                var pillTplObj = $(defaultPillRepeaterTmpl)
                        .append(selectedTemplate);

                element.find(".msi-selected-lst-cntnr")
                        .append(pillTplObj);

                dropdownContainerObj.append(footerTemplate);

                //// console.log("appleds", inputButtonObj.find(".msi-selected-lst-cntnr").html());

                // remove transcluded template - else Angular will throw
                // Error: [ngTransclude:orphan] Illegal use of ngTransclude directive
                element.find("[ng-transclude]").remove();

                // finally recompile with new inner templates
                // have to remove the old one to avoid event handlers being fired twice
                var html = element.html();
                element.contents().remove();
                element.html(html);
                $compile(element.contents())($scope);

                // update cached objects after HTML rebuild
                inputButtonObj = angular.element(element.find("button[ng-attr-id]")[0]);
                dropdownContainerObj = angular.element(element.find(".msi-select-dropdown")[0]);
                columnContainerObj = angular.element(element.find(".msi-select-col-cntnr")[0]);

                itemListContainerObj = angular.element(element.find(".msi-select-list-cntnr")[0]);
                itemListObj = angular.element(element.find(".msi-select-itm-list")[0]);

                var selectedItemListObj = angular.element(element.find(".msi-selected-lst-cntnr")[0]);


                // attach kbd listeners
                inputButtonObj.on("keydown", keyboardListener);
                inputButtonObj.on("keypress", charListener);

                var oldParentInfo = null;

                if ($scope.appendToSelector) {
                    // after done, save old settings for current parent so we can restore later
                    $timeout(function () {

                        oldParentInfo = {
                            parentObj: element.parent(),
                            oldStyles: element.attr("style") || ""
                        };
                    }, 0);
                }

                // attach validator for "required"
                // For DOM -> model validation
                ngModelCtrl.$parsers.unshift(function (value) {
                    // value is not selected at this point, but about to be selected
                    var valid = isValidRequiredSelection(value);
                    ngModelCtrl.$setValidity("required", valid);

                    // good practice - return undefined for invalid
                    // if it's valid, return the value to the model,
                    // otherwise return undefined.
                    return valid ? value : undefined;
                });

                // For model -> DOM validation
                ngModelCtrl.$formatters.unshift(function (value) {
                    // value is not selected at this point, but about to be selected
                    var valid = isValidRequiredSelection(value);
                    ngModelCtrl.$setValidity("required", valid);

                    // return the value or nothing will be written to the DOM.
                    return value;
                });

                // do first source transform
                updateColumnsFromSourceArray();

                // watchers

                // could not use dataSource for sourceArray
                // because it leads to data-source which contains HTML5 reserved prefix "data-"

                $scope.$watch("sourceArray", function (sourceArray) {

                    // data source is about to be changed, we cannot be sure anymore
                    // if our selection is applicable at all
                    // so we just clear it away in case of doubts

                    //// console.log("sourceArray", sourceArray);
                    if ($scope.selectedItems.length) {
                        // something was previously selected, but is not valid source item anymore
                        clear();
                    }
                });

                $scope.$watchCollection("sourceArray", function (newData) {

                    // do transforms only after data has loaded
                    $timeout(function () {

                        updateColumnsFromSourceArray();

                        // data source is about to be changed, we cannot be sure anymore
                        // if our selection is applicable at all
                        // so we just clear it away in case of doubts

                        //// console.log("sourceArray $watchCollection", newData);
                        var oldSelection = $scope.appliedItems;
                        if ($scope.selectedItems.length) {
                            clear();
                        }

                        // now retry selecting previous applied items
                        // because the collection changed, but the array is still the same
                        if (oldSelection.length) {

                            //// console.log("old matches", oldSelection);
                            oldSelection.forEach(function (match) {
                                var ix = $scope.sourceArray.indexOf(match);

                                if (ix !== -1) {
                                    // select last matching by ref
                                    addItemToSelection(match);
                                }
                            });

                            // update final state because ng-model is the authority
                            pullAppliedFromSelection();

                            // if dropdown is open, we have to scroll to the first selected item
                            scrollToSelectedIfOpen();
                        }
                    }, 0);
                });

                $scope.$watch("ngModel", function (data) {

                    // notice - this might be called by self induced ngModel changes
                    // in which case we should most probbaly ignore it
                    if(selfInducedModelChange){
                        selfInducedModelChange = false;
                        return;
                    }

                    // if not array, then make it
                    // even undefined will do as a valid key, if we have it in source array
                    if (!Array.isArray(data)) {
                        data = [data];
                    }

                    // the following code is slow-ish,
                    // but we have no other way to protect against self induced watches
                    var matches = findItemsByModel(data);

                    // arrays must have the same objects on both sides
                    // order does not matter
                    var notInModel = $scope.appliedItems.filter(function (value) {
                        return matches.indexOf(value) === -1;
                    });
                    var notApplied = matches.filter(function (value) {
                        return $scope.appliedItems.indexOf(value) === -1;
                    });

                    //// console.log("diffs are", notInModel, notApplied);
                    //// console.log("applied are", $scope.appliedItems);

                    if (notApplied.length === 0 && notInModel.length === 0) {
                        // nothing changed, possibly triggered by inner selection command
                        // so skip to avoid duplicate events
                        return;
                    }

                    // note - this will be called also when we change ngModel from inside
                    // but it should not cause any troubles
                    clear();

                    // sync with index, if we can
                    if (!$scope.sourceArray || $scope.sourceArray.length === 0) {
                        return;
                    }

                    matches.forEach(function (match) {
                        var ix = $scope.sourceArray.indexOf(match);
                        //// console.log("maybe adding to selection", match, ix);
                        if (ix !== -1) {
                            // select last matching by ref
                            addItemToSelection(match);
                        }
                    });

                    //// console.log("selection is", $scope.selectedItems);

                    // update final state because ng-model is the authority
                    pullAppliedFromSelection();

                    //// console.log("applied was synced to", $scope.appliedItems);

                    // if dropdown is open, we have to scroll to the first selected item
                    scrollToSelectedIfOpen();
                });

                $scope.$on('$destroy', function () {
                    //// console.log("destroy");
                    // remove all events
                    inputButtonObj.off();
                });

                var ignoreNextFocus = false;

                // events
                $scope.onFocus = function (e) {
                    // order of following IFs is very important!

                    // check if it was inner forced focus call
                    // even when nothing has really changed
                    // to silence redundant events
                    if (ignoreNextFocus) {
                        // reset
                        ignoreNextFocus = false;
                        return;
                    }

                    if ($scope.ngFocusExpression) {
                        // exec callback function on parent scope
                        $scope.$parent.$eval($scope.ngFocusExpression);
                    }
                };

                $scope.onBlur = function (e) {

                    // order of following IFs is very important!

                    // the problem - if we picked an item, browser sends us a blur()
                    // the outer blur should not be triggered until dropdown has been closed

                    // if we press tab while dropdown open, TAB and blur will be killed in key event
                    // therefore we can safely ignore it here -
                    // if blur occured while open, then it was a click on item
                    if ($scope.isDropdownOpen) {
                        return;
                    }

                    if ($scope.ngBlurExpression) {

                        // exec callback function on parent scope
                        $scope.$parent.$eval($scope.ngBlurExpression);
                    }

                    $scope.$emit("msi-select-left");
                };

                // scope functions
                // default html select toggles when clicking,
                // so we do the same to be compatible
                $scope.mainBtnClicked = function () {

                    // console.log("toggle", evt);

                    if ($scope.isDropdownOpen) {
                        // close in timer to give Angular some time
                        $timeout(function () {
                            $scope.close(true); // clsoing on click is always called by me to keep focus
                        }, 0);
                    } else {
                        // close in timer to give Angular some time
                        $timeout(function () {
                            $scope.open();
                        }, 0);
                    }
                };

                $scope.open = function () {
                    if ($scope.isDropdownOpen) {
                        return;
                    }

                    // unfocus if any was focused
                    $scope.focusedItem = null;

                    // first move and then open
                    moveToAppendSpecifiedParent(function () {
                        $scope.isDropdownOpen = true;

                        // attach event handler to outer HTML to capture clicks outside
                        angular.element(document).on("click", externalClickListener);
                        adjustScrollbars(function () {
                            // when scrollbar is ready, scroll to the first selected
                            scrollToSelectedIfOpen();
                        });

                        // refocus after moved
                        focusMyself();

                        $('.msi-select-dropdown').each(function (index, select) {
                            var offsetTop = $(select).offset().top,
                                height    = $(select).height();

                            if ((offsetTop + height) > window.innerHeight) {
                                $(select).addClass('top');
                            } else {
                                $(select).removeClass('top');
                            }
                        });
                    });
                };

                $scope.close = function (calledByMe) {

                    // always try to remove outer event handler
                    angular.element(document).off("click", externalClickListener);

                    // first close and then move back, if needed
                    $scope.isDropdownOpen = false;

                    moveBackFromAppendSpecifiedParent(function () {

                        // console.log("moveBackFromAppendSpecifiedParent calledByMe", calledByMe);
                        // do not sabotage outside clicks and focusing on other inputs
                        if (calledByMe) {
                            // problem - this will trigger focus event and also blur
                            // even when nothing has really changed
                            // so we silence redundant events using ignoreNextFocus flag
                            focusMyself();
                        }

                        $('.msi-select-dropdown').each(function (index, select) {
                            $(select).removeClass('top');
                        });
                    });
                };

                $scope.removeItemClicked = function (item, $event) {

                    // stop from bubbling up to avoid toggling the dropdown
                    $event.stopPropagation();

                    removeItemFromSelection(item);

                    // push right now
                    pushSelectionToModel();

                    // refocus after click
                    focusMyself();
                };

                $scope.itemClicked = function (item) {

                    $scope.focusedItem = item;

                    if ($scope.isMultiSelectMode &&
                            $scope.isItemSelected(item)) {
                        removeItemFromSelection(item);
                    } else {
                        addItemToSelection(item);
                    }

                    // close only if allowed immediate apply
                    if (pushImmediatelyIfAllowed()) {
                        $scope.close(true);
                    }
                };

                $scope.selectAllClicked = function () {

                    selectAll();

                    //// console.log($scope.selectedItems, $scope.selectedIndexes);

                    // close only if allowed immediate apply
                    if (pushImmediatelyIfAllowed()) {
                        $scope.close(true);
                    }
                };

                $scope.selectNoneClicked = function () {

                    selectNone();

                    // close only if allowed immediate apply
                    if (pushImmediatelyIfAllowed()) {
                        $scope.close(true);
                    }
                };

                $scope.applyClicked = function () {

                    // push and close
                    pushSelectionToModel();
                    $scope.close(true);
                };

                $scope.selectCntnrClicked = function ($event) {
                    // cannot stop from bubbling up - other selects will not permit closing of this one
                    //$event.stopPropagation();
                    //$event.preventDefault();

                    // console.log("selectCntnrClicked", $event);
                    $scope.mainBtnClicked();// click through
                    //focusMyself();
                };

                $scope.getTooltip = function () {

                    // avoid obscuring the dropdown
                    if ($scope.isDropdownOpen) {
                        return "";
                    }

                    if (!$scope.tooltipProperty ||
                            $scope.appliedItems.length === 0) {
                        return "";
                    }

                    if (!$scope.appliedItems[0][$scope.tooltipProperty]) {
                        return "";
                    }

                    // concat all values?
                    var tooltips = $scope.appliedItems.map(function (item) {
                        return item[$scope.tooltipProperty];
                    });

                    var selected = tooltips.join("; ");
                    // do not make huge tooltip
                    if (selected.length > 100) {
                        selected = selected.substring(0, 100) + "...";
                    }
                    return selected;
                };

                $scope.isItemSelected = function (item) {
                    return $scope.selectedItems.indexOf(item) !== -1;
                };

                // private functions

                function updateColumnsFromSourceArray() {

                    // column setup
                    $scope.sourceMatrix = [];

                    for (var i = 0; i < $scope.columnCount; i++) {
                        $scope.sourceMatrix.push([]);
                    }

                    // sanity check
                    if (!$scope.sourceArray) {
                        return;
                    }

                    // try dividing source array vertically
                    // A C
                    // B

                    // use ceil to make all fit
                    // last column might have less records
                    var perColumn = Math.ceil($scope.sourceArray.length / $scope.columnCount);

                    // fill with data
                    var col = 0;
                    for (var i = 0; i < $scope.sourceArray.length; i++) {

                        $scope.sourceMatrix[col].push($scope.sourceArray[i]);

                        // move to next col on each boundary
                        if ((i + 1) % perColumn === 0) {
                            col++;
                        }
                    }

                    // we might have empty last columns in some occasions
                    // e.g. 4 items in 4 cols - divide ceil gives 2 in col
                    // and no items in last col
                    if ($scope.sourceMatrix[$scope.columnCount - 1].length === 0) {
                        $scope.sourceMatrix.pop();
                    }

                    // console.log("$scope.sourceMatrix", $scope.sourceMatrix, $scope.columnCount);

                    // for faster focus while scrolling, we cache jQuery elements
                    // but after angular has digested
                    $timeout(function () {

                        domListItemsCache = [];
                        for (var i = 0; i < $scope.sourceMatrix.length; i++) {
                            // column
                            var items =
                                    angular.element(
                                            columnContainerObj.find(".msi-select-list-cntnr")[i])
                                    .find(".msi-select-itm-list") // rows
                                    .children();

                            domListItemsCache.push(items);
                        }

                        // fix column filling styles
                        if ($scope.sourceMatrix.length < 2) {
                            angular.element(
                                    columnContainerObj.find(".msi-select-list-cntnr")[0])
                                    .addClass("single");
                        }

                    }, 0, false);// no model changes expected
                }

                function indexToMatrix(index) {
                    var xy = {x: -1, y: -1};

                    if (!$scope.sourceArray || $scope.sourceArray.length <= index) {
                        return xy;
                    }

                    var perColumn = Math.ceil($scope.sourceArray.length / $scope.columnCount);

                    // column divide
                    xy.x = Math.floor(index / perColumn);
                    xy.y = index - xy.x * perColumn; // subtract previous columns

                    return xy;
                }

                function matrixToIndex(xy) {
                    // sanity check
                    if (!xy || xy.x < 0 ||
                            xy.y < 0 ||
                            xy.x >= $scope.sourceMatrix.length ||
                            xy.y >= $scope.sourceMatrix[xy.x].length) {
                        return -1;
                    }

                    var perColumn = Math.ceil($scope.sourceArray.length / $scope.columnCount);
                    var index = xy.x * perColumn + xy.y;

                    return index;
                }

                function selectAll() {
                    $scope.selectedItems = $scope.sourceArray.slice();
                    // JS trick to copy array items into new instance

                    // make all indexes selected
                    $scope.selectedIndexes = $scope.selectedItems.map(function (item, ix) {
                        return ix;
                    });
                }

                function selectNone() {
                    $scope.selectedItems = [];
                    $scope.selectedIndexes = [];
                }

                function moveToAppendSpecifiedParent(cbf) {
                    if (!$scope.appendToSelector) {
                        if (cbf) {
                            cbf();
                        }
                        return false;
                    }

                    $timeout(function () {
                        var parent = $($scope.appendToSelector).first();

                        if (parent.length !== 1) {
                            if (cbf) {
                                cbf();
                            }
                            return;
                        }

                        var offset = element.offset();
                        var parentOffset = parent.offset();

                        element.css({
                            "position": "absolute",
                            "top": offset.top - parentOffset.top + "px",
                            "left": offset.left - parentOffset.left + "px",
                            "width": element.width() + "px",
                            "height": element.height() + "px"
                        });

                        parent.append(element);

                        // after element is gone, need to fill empty space with something
                        // to avoid jumping of siblings
                        var placeHolder = $('<div id="' +
                                $scope.controlId +
                                '_plchldr" style="width:' +
                                element.width() + 'px;height:' +
                                element.height() + 'px;">');

                        oldParentInfo.parentObj.append(placeHolder);

                        if (cbf) {
                            cbf();
                        }
                    }, 0);

                    return true;
                }

                function moveBackFromAppendSpecifiedParent(cbf) {
                    if (!oldParentInfo) {
                        if (cbf) {
                            cbf();
                        }
                        return false;
                    }

                    $timeout(function () {

                        element.attr("style", oldParentInfo.oldStyles);

                        // remove the placeholder
                        $("#" + $scope.controlId + "_plchldr").remove();

                        oldParentInfo.parentObj.append(element);

                        $timeout(function () {
                            // fix the style to actual selection because old styles might be outdated
                            elemHeightToSelection(selectedItemListObj.height());

                            if (cbf) {
                                cbf();
                            }

                        }, 0); // model changes expected

                    }, 0); // model changes expected - height might change

                    return true;
                }

                function scrollToSelectedIfOpen() {
                    if ($scope.selectedItems.length !== 0) {
                        scrollToItemIfOpen(
                                // last item
                                $scope.selectedItems[$scope.selectedItems.length - 1]
                                );
                    }
                }

                function scrollToItemIfOpen(item) {
                    if ($scope.isDropdownOpen) {
                        $timeout(function () {
                            scrollToItemByIndex($scope.sourceArray.indexOf(item));
                        }, 0);
                    }
                }

                function getItemDimensions(index) {
                    // sanity check
                    if (index < 0) {
                        return {x: 0, y: 0, w: 0, h: 0};
                    }

                    // multiple lists here for multiple <li>
                    var mIx = indexToMatrix(index);

                    // console.log("indexToMatrix", index, mIx);

                    // column
                    var items = domListItemsCache[mIx.x];

                    if (mIx.y < 0 || items.length <= mIx.y) {
                        return {x: 0, y: 0}; // problem - not enough items found
                    }

                    // console.log("getItemDimensions(index)", index, items[mIx.y]);

                    return {
                        x: items[mIx.y].offsetLeft,
                        y: items[mIx.y].offsetTop,
                        w: items[mIx.y].offsetWidth,
                        h: items[mIx.y].offsetHeight
                    };
                }

                function scrollToItemByIndex(index) {
                    $timeout(function () {
                        var viewportHeight = columnContainerObj.height();
                        var viewportWidth = columnContainerObj.width();
                        var dims = getItemDimensions(index);

                        //console.log("scrollToItemByIndex", index, viewportHeight, dims);

                        // do some precalc -
                        // scroll only when there is a risk that if will get out of view
                        // different browsers do this in different ways
                        // we picked IE way - scroll one by one to direction of scolling

                        // is the item below bottom?
                        // scroll minimally to make it fully visible
                        var bottomNeeded = dims.y + dims.h;
                        var rightNeeded = dims.x + dims.w;

                        if (!ngScroller) {
                            // add some more for std scrollbars
                            bottomNeeded += 20;
                            rightNeeded += 20;
                        }

                        var topNeeded = dims.y;
                        var leftNeeded = dims.x;
                        var currentOffsetY = $scope.scrollOffset.y; // assume the same, no scrolling
                        var currentOffsetX = $scope.scrollOffset.x; // assume the same, no scrolling

                        //// console.log("topNeeded, bottomNeeded", topNeeded, bottomNeeded);
                        //// console.log("currentOffsetY, viewportHeight", currentOffsetY, viewportHeight);

                        // scroll to one direction only
                        if (($scope.scrollOffset.y + viewportHeight) < bottomNeeded) {
                            // was not scrolled enough, scroll more
                            // console.log("need bottom");
                            currentOffsetY = Math.max(0, bottomNeeded - viewportHeight);
                        }
                        else
                        if ($scope.scrollOffset.y > topNeeded) {
                            // was scrolled too far, scroll back
                            //// console.log("need top");
                            currentOffsetY = topNeeded;
                        }

                        // scroll to one direction only
                        if (($scope.scrollOffset.x + viewportWidth) < rightNeeded) {
                            // was not scrolled enough, scroll more
                            // console.log("need right");
                            currentOffsetX = Math.max(0, rightNeeded - viewportWidth);
                        }
                        else
                        if ($scope.scrollOffset.x > leftNeeded) {
                            // was scrolled too far, scroll back
                            //// console.log("need left");
                            currentOffsetX = leftNeeded;
                        }

                        // knwon bug - ngScroller sometimes gets glitchy
                        // and does not scroll where requested
                        // because initial or manual scroll is in progress

                        // applying changes for each scrollbar separately seem to help
                        // console.log("try scroll to", currentOffsetX, currentOffsetY);
                        $scope.scrollOffset.y = currentOffsetY;
                        $scope.$digest();

                        $scope.scrollOffset.x = currentOffsetX;
                        $scope.$digest();
                        //console.log("finally scrolled to", $scope.scrollOffset);

                        // which scroller do we have?
                        // if have ng-scrollable
                        if (ngScroller) {
                            // ng-scrollable watches $scope.scrollOffset through spyY
                            // so nothing more to do
                            return;
                        }

                        columnContainerObj.scrollTop($scope.scrollOffset.y);
                        columnContainerObj.scrollLeft($scope.scrollOffset.x);

                    }, 50); // make sure it comes a bit after scrollbars are inited
                }

                function adjustScrollbars(whenFinish) {

                    // to be safe - wait till bindings are rendered
                    $timeout(function () {
                        // unfortunately this won't work reliably because of different browser behavior
                        // for scrollbars - some float above, some outside and some inside

                        // var hasVScroll = columnContainerObj[0].scrollHeight > dropdownContainerObj[0].clientHeight;

                        //if(hasVScroll){
                        // need some scrolling adjustments to fix redundant width when vertical scrollbar is visible
                        // columnContainerObj.css("padding-right", "20px");
                        //}

                        // rebuild the ngScrollbar, if exists
                        if (ngScroller) {
                            $scope.$broadcast("content.changed");
                            $scope.$broadcast("content.reload");
                        }

                        if (whenFinish) {
                            whenFinish();
                        }
                    }, 0, false);  // no model changes
                }

                function isValidRequiredSelection(values) {

                    // value is not selected at this point, but about to be selected
                    if (!$scope.isRequired) {
                        return true; // don't care if not required
                    }

                    if (!Array.isArray(values)) {
                        values = [values];
                    }

                    var items = findItemsByModel(values);

                    return items.length !== 0;
                }

                function findItemsByModel(values) {
                    var valueMatches = null, sourceMatches = [];

                    // safety check
                    if (!$scope.sourceArray || !values || values.length === 0) {
                        return sourceMatches;
                    }

                    // look for matching objects in source array
                    // if values contain something unrecognized, it will be ignored
                    sourceMatches = $scope.sourceArray.filter(function (item) {

                        if ($scope.bindModelToProperty) {
                            // find by matching property value
                            valueMatches = values.filter(function (value) {
                                return item[$scope.bindModelToProperty] === value;
                            });

                        } else {
                            // find by matching ref
                            valueMatches = values.filter(function (value) {
                                return item === value;
                            });
                        }

                        if (valueMatches.length > 1) {
                            // console.warn("msi-select: Duplicate objects found in selected values");
                        }

                        return valueMatches.length > 0;
                    });

                    if (sourceMatches.length > 1 && !$scope.isMultiSelectMode) {
                        // console.warn("msi-select: Duplicate objects found in source array for selected values while in single-select mode. Last value will get selected.");
                    }

                    return sourceMatches;
                }

                function removeItemFromSelection(item) {
                    //// console.log("selecting items", item);

                    if ($scope.selectedItems.indexOf(item) === -1) {
                        return;
                        // we already had this, this is redundant call,
                        //so just exit
                    }

                    if (!$scope.isMultiSelectMode) {
                        // just destroy old items, can select only one
                        $scope.selectedItems = [];
                        $scope.selectedIndexes = [];

                        return;
                    }

                    $scope.selectedItems = $scope.selectedItems.filter(function (element, i) {
                        return element !== item;
                    });

                    var existIx = $scope.sourceArray.indexOf(item);
                    $scope.selectedIndexes = $scope.selectedIndexes.filter(function (element, i) {
                        return element !== existIx;
                    });

                }

                function addItemToSelection(item) {
                    //// console.log("selecting items", item);

                    if ($scope.selectedItems.indexOf(item) !== -1) {
                        return;
                        // we already had this, this is redundant call,
                        //so just exit
                    }

                    // sanity check
                    if (!$scope.sourceArray || $scope.sourceArray.length === 0) {
                        return;
                    }

                    if (!$scope.isMultiSelectMode) {
                        // destroy old items, can select only one
                        $scope.selectedItems = [];
                        $scope.selectedIndexes = [];
                    }

                    $scope.selectedItems.push(item);
                    $scope.selectedIndexes.push($scope.sourceArray.indexOf(item));
                }

                function pullAppliedFromSelection() {

                    $scope.appliedItems = $scope.selectedItems.slice();
                    // JS trick to copy array items into new instance
                }

                var selfInducedModelChange = false;

                function pushSelectionToModel() {

                    // notify the validator that user himself has changed something
                    ngModelCtrl.$setDirty();
                    ngModelCtrl.$setTouched();

                    //// console.log("ngModel will be pushed from", $scope.ngModel);

                    $scope.appliedItems = $scope.selectedItems.slice();
                    // JS trick to copy array items into new instance

                    if ($scope.appliedItems.length === 0) {
                        $scope.ngModel = null; // TODO: shouldn't set to empty array in multimode?

                        // notify outer world about changes
                        element.triggerHandler("change");
                        $scope.$emit("msi-select-applied");

                        return;
                    }

                    var bindable = $scope.appliedItems;
                    if ($scope.bindModelToProperty) {
                        bindable = $scope.appliedItems.map(function (item) {
                            return item[$scope.bindModelToProperty];
                        });
                    }

                    if ($scope.isMultiSelectMode) {
                        $scope.ngModel = bindable;
                    } else {
                        // single first element
                        $scope.ngModel = bindable[0];
                    }

                    // trying to protect from redundant calls on ng-model watcher
                    selfInducedModelChange = true;
                    //// console.log("ngModel was being pushed to", $scope.ngModel);
                    //// console.log("applied items is", $scope.appliedItems);

                    // notify outer world about changes
                    element.triggerHandler("change");
                    $scope.$emit("msi-select-applied");
                }

                // to adjust button and element height to selected content
                // whenever it changes
                $scope.$watch(
                   function () {
                       return selectedItemListObj.height();
                   },
                   function (newValue, oldValue) {
                     if (newValue !== oldValue) {
                        elemHeightToSelection(newValue);
                     }
                   }
                );

                function elemHeightToSelection(height){
                    var setHeight = "";// empty string should remove property
                        // so it is restored to default

                    // console.log(selectedItemListObj);

                    // if selection was not cleared
                    if(height !== 0){
                        setHeight = selectedItemListObj.outerHeight();

                        // ensure bottom padding stays large enough
                        // to compensate for top
                        setHeight += selectedItemListObj.position().top;

                        //console.log("selector height",
                        //    selectedItemListObj.height());
                        //console.log("top",
                        //    selectedItemListObj.position().top);

                        //console.log("element height",
                        //    element.height());

                        // make string
                        setHeight += "px";

                        //console.log("final height",
                        //    setHeight);
                    }

                    // use min instead of height in case outer CSS specifies a larger value
                    //inputButtonObj.css("min-height", setHeight);
                    element.css("min-height", setHeight);
                }

                function pushImmediatelyIfAllowed() {
                    if (!$scope.applyImmediately) {
                        return false;
                    }

                    pushSelectionToModel();
                    return true;
                }

                function clear() {

                    $scope.selectedItems = [];
                    $scope.selectedIndexes = [];
                    $scope.appliedItems = [];
                }

                function markCurrentFocusedItem(item) {
                    $scope.focusedItem = item;

                    //// console.log("markCurrentFocusedItem", $scope.focusedItem);
                }

                // handle clicks outside the button / multi select layer
                // but only when opened
                function externalClickListener(e) {

                    // ignore if it was not external but inside of me
                    // prefilter by tag name to make it more efficient
                    var targetsArr = element.find(e.target.tagName);
                    // console.log("externalClickListener", targetsArr);

                    for (var i = 0; i < targetsArr.length; i++) {
                        if (e.target === targetsArr[i]) {

                            // console.log("inside, refocus");

                            focusMyself();

                            return;
                        }
                    }

                    // close in timer to give Angular some time
                    $timeout(function () {
                        $scope.close(false);

                        // if clicked outside, then can finally blur
                        $scope.onBlur(e);
                    }, 0);
                }

                function focusMyself(){
                    // refocus to assure we don't lose it
                    ignoreNextFocus = true;
                    // hopefully, no redundant events happen
                    // if the button was already focused
                    inputButtonObj.focus();
                }


                function killEvent(e) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                // search by keyboard, as standard HTML select does
                var searchable = "";

                function propertyStartsWithCi(subject, find) {
                    subject = subject + ""; // in case it was numeric
                    if (!subject) {
                        return false;
                    }
                    return subject.toLowerCase().indexOf(find.toLowerCase()) === 0;
                }

                function selectBySearch() {
                    var found = null;
                    if (!searchable || !$scope.sourceArray) {
                        searchable = "";
                        return null;
                    }

                    // find by matching property value
                    var matches = $scope.sourceArray.filter(function (item) {
                        if ($scope.searchByProperty) {
                            return propertyStartsWithCi(item[$scope.searchByProperty], searchable);
                        }

                        // guessing
                        return propertyStartsWithCi(item.name, searchable) ||
                                propertyStartsWithCi(item.label, searchable) ||
                                propertyStartsWithCi(item.title, searchable);
                    });

                    if (matches.length > 0) {
                        found = matches[0];
                    }

                    //// console.log("searchable, found", searchable, found);

                    // as <select>, reset searchable when no matches found
                    if (!found) {
                        searchable = "";
                        return null;
                    }

                    goToItemByIndex($scope.sourceArray.indexOf(found));
                }

                function charListener(e) {
                    var key = e.keyCode ? e.keyCode : e.which;
                    var c = String.fromCharCode(key);

                    // only keypress give unicode characters
                    searchable += c;

                    // wait for next apply/digest after key has been released
                    // to prevent drawing issues
                    $timeout(function () {
                        selectBySearch();
                    }, 0);
                }

                var debounceTime = new Date().getTime();

                // navigate using up and down arrows,
                // open/close and select values
                function keyboardListener(e) {

                    var key = e.keyCode ? e.keyCode : e.which;

                    // console.log(key);

                    if (key === 32) {
                        // charListener(e); // pass to searcher before cancel
                        // ??? questionable, left for future consideration

                        if ($scope.focusedItem && $scope.isMultiSelectMode) {
                            // select/unselect, if multi mode
                            if($scope.isItemSelected($scope.focusedItem)){
                                removeItemFromSelection($scope.focusedItem);
                            }else{
                                addItemToSelection($scope.focusedItem);
                            }
                        }

                        // silence spacebar to avoid scrolling
                        // and closing
                        return killEvent(e);
                    }

                    // prevent UI freezes because of holding a key
                    var nowTime = new Date().getTime();
                    // let through A key because Ctrl and/Or Shift bounces insanely
                    // but we need to detect when they are used in combo with A
                    if ((key !== 65) && (nowTime - debounceTime) < 50) {
                        return killEvent(e);
                    }

                    debounceTime = nowTime;

                    // backspace - do nothing
                    if (key === 8) {

                        // silence it to prevent back navigations
                        return killEvent(e);
                    }

                    // escape, tab

                    // default html select allows to select items whith ESC and TAB key
                    // if item is moused over,
                    // so we do the same to be compatible
                    if (key === 27 || key === 9) {
                        // if hidden - do nothing
                        if (!$scope.isDropdownOpen) {
                            // silence ESC, but let TAB through
                            if (key === 27) {

                                $scope.$emit("msi-select-escaped");

                                return killEvent(e);
                            }

                            // let TAB through for bluring and going to next input
                            return;
                        }

                        // if here, then it was open

                        // close in timer to give Angular some time
                        $timeout(function () {
                            $scope.close(true);// to silence events
                        }, 0);

                        // finally silence ESC always
                        if (key === 27) {

                            $scope.$emit("msi-select-escaped");

                            return killEvent(e);
                        }

                        // finally, if dropdown is visible, we want to prevent redundant blurs
                        if (key === 9 && $scope.isDropdownOpen) {
                            // so we silence also TAB, if dropdown was open
                            return killEvent(e);
                        }

                        return;
                    }

                    // enter
                    if (key === 13) {
                        // hidden - just open
                        if (!$scope.isDropdownOpen) {
                            // open in timer to give Angular some time
                            $timeout(function () {
                                $scope.open();
                            }, 0);

                            // silence it
                            return killEvent(e);
                        }

                        // enter always pushes and closes
                        pushSelectionToModel();
                        $scope.close(true);

                        return killEvent(e);
                    }

                    // navigation keys
                    if (!$scope.sourceArray.length || $scope.sourceArray.length === 0) {
                        // no items, nothing to do
                        // silence it
                        return killEvent(e);
                    }

                    // kbd navigation usually selects the element immediately in single select mode
                    // in case if mouse is on some other value, default select jumps to it after a moment
                    // we won't try to accurately replicate that here
                    // but be close to that

                    // try preselecting no item
                    var activeItem = -1;

                    // determine current selected or focused item
                    // and take it as the base for navigation start
                    if ($scope.focusedItem) {
                        var ix = $scope.sourceArray.indexOf($scope.focusedItem);
                        if (ix > -1) {
                            activeItem = ix;
                        }
                    }

                    // if still no active item, then try the first of selected ones
                    if (!$scope.isMultiSelectMode &&
                            activeItem === -1 && $scope.selectedIndexes.length > 0) {
                        activeItem = $scope.selectedIndexes[0];
                    }

                    // up/down - just move through the list, jumping columns,
                    // if current end is reached

                    // next element (down key)
                    if (key === 40) {

                        //console.log("key === 40", activeItem);

                        if (activeItem >= ($scope.sourceArray.length - 1)) {
                            return killEvent(e);// nothing to do, we are at the end or even farther, if something glitched
                        }

                        activeItem += 1;

                        // in case nothing was selected, then first item will become selected -
                        // the same behavior as for html select
                        //// console.log("add push item", newSelected);
                        return goToItemByIndex(activeItem, e);
                    }

                    // previous element (up key)
                    if (key === 38) {

                        // previous or nothing
                        if (activeItem <= 0) {
                            return killEvent(e);// nothing to do, no selection nor topmost item
                        }

                        // try preselecting previous or nothing
                        activeItem -= 1;

                        return goToItemByIndex(activeItem, e);
                    }

                    // column left
                    if (key === 37) {

                        // cannot move left if nothing selected or first elemet
                        if (activeItem <= 0) {
                            return killEvent(e);
                        }

                        var mIx = indexToMatrix(activeItem);
                        activeItem = matrixToIndex({x: mIx.x - 1, y: mIx.y});

                        if (activeItem < 0) {
                            return killEvent(e);// cannot go left, first or no column
                        }

                        return goToItemByIndex(activeItem, e);
                    }

                    // column right
                    if (key === 39) {
                        // cannot move right if nothing selected or last elemet
                        // console.log("right", activeItem);
                        if ((activeItem < 0) ||
                                (activeItem >= ($scope.sourceArray.length - 1))) {
                            return killEvent(e);
                        }

                        var mIx = indexToMatrix(activeItem);

                        activeItem = matrixToIndex({x: mIx.x + 1, y: mIx.y});
                        // console.log("right", mIx, activeItem);

                        if (activeItem < 0) {
                            return killEvent(e);// cannot go right, last or no column
                        }

                        return goToItemByIndex(activeItem, e);
                    }

                    // Ctrl-A, Shift-Ctrl-A
                    if (!$scope.applyImmediately && $scope.isDropdownOpen) {
                        if (key === 65) {
                            //// console.log(e);
                            if (e.ctrlKey && e.shiftKey) {
                                selectNone();
                                return killEvent(e);
                            }

                            if (e.ctrlKey) {
                                selectAll();
                                return killEvent(e);
                            }
                        }
                    }
                }

                // helper
                function goToItemByIndex(newSelected, eventToKill) {
                    var item = $scope.sourceArray[newSelected];

                    //// console.log("goToItemByIndex ix, item", newSelected, item);
                    if (!$scope.isMultiSelectMode) {
                        // select, if single mode
                        addItemToSelection(item);
                    } else {
                        // just make focused - only in multiselect
                        markCurrentFocusedItem(item);
                    }

                    pushImmediatelyIfAllowed();

                    // if dropdown is open,
                    // we have to scroll to the focused item
                    scrollToItemIfOpen(item);

                    if (eventToKill) {
                        killEvent(eventToKill);
                    }
                }
            }
        };
    }

})(window.angular);
