(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
            .service('ClientService',
                ['$resource', ClientService]);

    function ClientService($resource) {
        return $resource('', {}, {
            'hasInstance': {
                url:    '/api/internal/clients/has-instance',
                method: 'POST',
                silentSuccess: true
            },
            'clientData': {
                url:    '/api/internal/clients/data',
                method: 'GET',
            },
            'clientObjects': {
                url:    '/api/internal/clients/objects',
                method: 'GET',
            },
            'clientDocuments': {
                url:    '/api/internal/clients/documents',
                method: 'GET',
            },
        });
    }

})(window.angular, window.Utilities);