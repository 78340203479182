(function(angular) {
  angular.module('amber').factory('ErrorFactory', function() {
    return {
      isAutocompleteError: function(error) {
        return (
          error.data &&
          error.data.errors &&
          error.data.errors.type === 302.1
        );
      }
    };
  });
})(window.angular);