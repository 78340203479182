//used on all expandable panels

// usage example

//  <amber-stateful-panel
//          is-persistant='true' //save open or collapsed state
//          is-required='true'  //show * next to
//          default-state="open" // open/collapsed
            //must pass user to id, so that each user on same device has
            //their own setttings
//          panel-id="repair_editor_work_hours{{ Auth::user()->id }}"
//          >
//      <pane-header>
//        //all header elements go here
//      </pane-header>
//      // all body elements go here
//  </amber-stafeul-panel>

//IMPOETANT!!!
//Do not use ng-if between <paneheader> </paneheader>
//Use ng-show

angular.module('amber').directive('amberStatefulPanel', ['$state',"$templateCache","$compile","$timeout",amberStatefulPanel])
                       .run(["$templateCache", Templater]);

function Templater($templateCache) {
                var defaultTmpl =
                                  '<div class="panel panel-block not-last-panel expandable" >'+
                                    '<div class="panel-heading">'+
                                       '<div class="icon expand">&#xE5CE;</div>'+
                                        //heading elements go here
                                    '</div>'+
                                    '<div class="panel-body" >'+
                                      '<ul class="panel-ul">'+
                                        '<div class="hider" >'+
                                          '<li>'+
                                            '<div class="panel-form-wrapper" ng-transclude >'+
                                            //hidden elements go here
                                            '</div>'+
                                          '</li>'+
                                        '</div>'+
                                      '</ul>'
                                    '</div>'+
                                 '</div>';
              var defaultHeaderTmpl='This panel is missing a header';

              $templateCache.put("amber-stateful-panel.html", defaultTmpl);
              $templateCache.put("amber-stateful-panel-header.html", defaultHeaderTmpl);
            }

function amberStatefulPanel($state,$templateCache, $compile,$timeout) {
  return {
    restrict: 'E',
    transclude:true,
    scope: false,
    templateUrl: "amber-stateful-panel.html",
    link: function($scope, element,$attr){
      var headerId=$attr.id;
      element.removeAttr('id');

      var headerTemplate = element.find("pane-header").html();
      var defaultHeaderTmpl = $templateCache.get("amber-stateful-panel-header.html");
      if (!headerTemplate) {
          //fallback message if no header
          headerTemplate = defaultHeaderTmpl;
      }

      //used in setting and setting opened state when loaded
      var panel = angular.element(element.find(".panel")),
          heading = angular.element(panel[0].firstChild),
          panelContent = panel.find('.panel-form-wrapper');

      //Must deal with attributes this way because scope must be false
      if ($attr.isRequired)
      {
          heading.addClass('required');
      }
      if ($attr.isNegative)
      {
          panel.removeClass('panel-block');
          panel.addClass('panel-negative');
      }

      var closedByDefault=false;
      if ($attr.defaultState=="collapsed")
      {
        closedByDefault=true;
      }

      element.find('.panel-heading').append(headerTemplate);
      //Must be set in link function, because scope must be set to false
      element.find('.panel-heading').attr("id",headerId);
      //TODO find better way to deal with pane header transcluding into body
      element.find('.panel-form-wrapper').find('pane-header').remove();
      //Must add click function this way or else it dublicates and runs 2 times when clicked
      element.find('.panel-heading').attr("ng-click","panelsExpandFromHeading($event)");

      $compile(element.find('.panel-heading'))($scope);

      //open close functions copied from MasterController
      // and edited to suit needs of directive
      $scope.panelsExpandFromHeading = function($event)
      {
        //Prevent click going through buttons, only permissible on
        //expand icon
        if (!(angular.element($event.target.parentNode).hasClass('panel'))
            && !angular.element($event.target).hasClass('expand') )
        {
          return false;
        }
        var heading = angular.element($event.currentTarget);
        var panel =  angular.element(heading[0].parentNode);
        var panelContent = panel.find('.panel-form-wrapper');

        heading.toggleClass('open');
        if (heading.hasClass('open')) {
          $scope.applyExpandedPanelPropertiesForBlockPanel(heading, panelContent, panel, $attr.isPersistant);
        } else {
          $scope.applyCollapsedPanelPropertiesForBlockPanel(heading, panelContent, panel, $attr.isPersistant);
        }
      }


      //must use heading[0].id to save in order to use multiple
      //amber-stateful panel directives inside single controller
      //It is do to fact that all amber-stateful panels essentially will have the same $scope
      $scope.applyExpandedPanelPropertiesForBlockPanel =function(heading, panelContent, panel,save) {
          heading.find('.expand').html('&#xE5CE;');
          panelContent.velocity('slideDown', { duration: 350 });

          panelContent.addClass('open');
          setTimeout(function () {
            panel.find('.hider').css('overflow', 'visible');
          }, 350);

           if (save==="true")
           {
             localStorage.removeItem('panel:' + heading[0].id);
             localStorage.setItem('panel:' + heading[0].id, 'open');
           }
      }
      $scope.applyCollapsedPanelPropertiesForBlockPanel = function(heading, panelContent, panel, save) {
        heading.find('.expand').html('&#xE5CF;');

        panelContent.velocity('slideUp', { duration: 350 });

        panelContent.removeClass('open');
        panel.find('.hider').css('overflow', 'hidden');

        if (save==="true")
        {
          localStorage.removeItem('panel:' + heading[0].id);
          localStorage.setItem('panel:' + heading[0].id, 'closed');
        }
      }

      //set open or closed state, determined by storage data and defaultState

        if (localStorage.getItem('panel:' + headerId) === 'closed' ||
          (!localStorage.getItem('panel:' + headerId) && closedByDefault)) {
           $scope.applyCollapsedPanelPropertiesForBlockPanel(heading, panelContent, panel,false)
        }
        if (localStorage.getItem('panel:' + headerId) === 'open' ||
        (!localStorage.getItem('panel:' + headerId) && !closedByDefault)) {
          heading.addClass('open');
          $scope.applyExpandedPanelPropertiesForBlockPanel(heading, panelContent, panel,false);
          }

    }
    }
  }
