/**
 * @author Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */
(function (angular) {
    "use strict";

    angular.module('common.services')
            .service('FormValidatorService', [
                '$timeout', FormValidatorService]);

    /**
     * Service for dealing with Bootstrap formError updates, resets 
     * and custom focusing on first invalid input
     *
     * @returns FormValidatorService
     */
    function FormValidatorService($timeout) {
        var that = this;

        this.validateWithFocus = function ($form, focusCallback) {

            // force Bootstrap error or validity styles
            // for this we'll have to find form's scope 
            // and broadcast on it to errorGroup directive

            // need HTML element here
            var elem = $('[name="' + $form.$name + '"]');
            var formParentScope = angular.element(elem).scope();

            if ($form.$valid) {   
                // nothing to do, but call error-aware to update field UI statuses

                // if have multiple forms, they all might be affected
                // therefore we pass the name to prevent this
                formParentScope.$broadcast("error-aware-check-validity", 
                                                $form.$name);
                
                return true; 
            }

            // find the invalid elements
            var invalids = elem.find(".ng-invalid"),
                    firstInvalid = invalids.first();

            if (firstInvalid.length !== 1) {
                return false; // give up, no ng-invalid
            }

            // this was forceful validation, make invalid fields dirty to show errors
            invalids.each(function(ix, elm){
                var formfield = $form[$(elm).attr("name")];
                    
                // make dirty
                formfield.$setDirty();
                formfield.$setTouched();
            });
            
            // update field UI statuses
            formParentScope.$broadcast("error-aware-check-validity",
                                        $form.$name);

            // input focusing logic follows
            
            // if we find one, set focus             
            // the timeout lets the digest / DOM cycle run before attempting to set focus
            $timeout(function () {
                
                // focus to control
                // process also custom controls which might have actual input hidden inside
                if (firstInvalid.hasClass("ui-select-bootstrap")) {
                    // there are multiple inputs and buttons, and not all work for focusing
                    firstInvalid.find("button.ui-select-match").click();
                }
                else {
                    // find if we have a custom control with redirected forcus
                    var fred = firstInvalid.attr("focus-redirect");
                    if(fred){
                        $(fred).focus();
                    } else {
                        firstInvalid.focus();
                    }
                }

                // signal to form, if required
                if (focusCallback) {
                    focusCallback(firstInvalid.attr("name"));
                }
            }, 100, false); // keep noticeable delay to avoid some unblock and other glitches

            return false; // form was invalid from the start
        };

        this.clearErrors = function ($form) {

            // force Bootstrap error or validity styles
            // for this we'll have to find form's scope 
            // and broadcast on it to errorGroup directive

            // need HTML element here
            var elem = $('[name="' + $form.$name + '"]');
            var formParentScope = angular.element(elem).scope();

            // remove all error statuses for every field
            angular.forEach($form, function (element) {
                if (typeof element === 'object') {
                    if (element.$setValidity && element.$name) {
                        element.$setUntouched();
                        element.$setPristine();
                        element.$setValidity("server-errors", true);
                        // custom errors
                        element.appServerErrors = null;
                    }
                }
            });

            // entire form
            $form.$setPristine();
            $form.$setUntouched();

            // if have multiple forms, they all might be affected
            // therefore we pass the name to prevent this
            formParentScope.$broadcast('error-aware-reset', $form.$name);
        };

        this.listenToServer = function (scope) {
            scope.$on("server-response-arrived", function (event, rejection, status) {
                if (rejection.data && rejection.data.errors &&
                        angular.isObject(rejection.data.errors)) {
                    that.assignServerErrors(rejection.data.errors);
                }
            });
        };

        /**
         * Usually should not be called manually,
         * unless server errors are expected to exist on page load
         * 
         * @param {type} errorData
         * @returns {Boolean}
         */
        this.assignServerErrors = function (errorData) {

            var $firstForm = null, $that = this;

            // should be valid JSON or object
            if (angular.isString(errorData)) {
                errorData = angular.fromJson(errorData);
            }

            if (!errorData || errorData.length === 0) {
                return false;
            }
            
            function removeServerErrors(formfield) {

                // give time for Angular to apply validity changes out of this scope
                $timeout(function () {
                    // if user has changed data, then server error is no more actual
                    formfield.$setValidity("server-errors", true);
                    formfield.appServerErrors = null;
                }, 0, true); //  true - Angular should update binidngs
            }
            
            // need break out of context - it might be an early call during init
            $timeout(function () {
                
                // iterating throgh object - not strictly correct, but Angular claims it's OK
                angular.forEach(errorData, function (errorMessages, key) {
                    var elem, form, formNameBound, $formParentScope,
                            $formCntrlr;

                    // find the field
                    elem = $('[name="' + key + '"]');

                    if (elem.length === 0) {
                        return;// no DOM elements
                    }

                    // find the Angular form controller for this field  
                    form = elem.closest("form");
                    $formParentScope = elem.scope();
                    if (form.length !== 1 || !$formParentScope) {
                        return; // not a form error, nothing to do
                    }

                    // form scope is not accessible through element.
                    // find through name syntax
                    formNameBound = form.attr("name");
                    if (!formNameBound) {
                        throw "Form must have a bound name to be accessible for server error validator";
                    }

                    // get to the scope
                    $formCntrlr = $formParentScope.$eval(formNameBound);

                    // save the form to validate and focus first input
                    if (!$firstForm) {
                        $firstForm = $formCntrlr;
                    } else {
                        if ($firstForm !== $formCntrlr) {
                            throw "Multiple forms in single response are not supported";
                        }
                    }
                    
                    var formfield = $formCntrlr[key];
                    
                    // make invalid with custom server messages
                    // IDEA: for future reuse: server could send just keys for specific messages 
                    // to pick from JS language texts
                    formfield.$setDirty();
                    formfield.$setTouched();
                    
                    // if items have empty texts, then ignore them - 
                    // special case to ignore - empty items are just server triggers for 
                    // JS `required` message
                    if(errorMessages.join().length) {
                        formfield.$setValidity("server-errors", false);
                        // we might have multiple errors for one field, 
                        // ng-messages does not deal with that,
                        // so we will piggyback them on to the $errors object
                        formfield.appServerErrors = errorMessages;
                        
                        var remover = function (event) {
                            removeServerErrors(formfield);
                        };
                        
                        // using one-time events to prevent leaking
                        
                        // remove them if user has changed anything
                        // because Angular won't do it automatically for custom errors
                        elem.one("keyup", remover);

                        // a workaround for browser autocomplete fields
                        // (email, password etc.) 
                        // which trigger change events on filling data
                        // and clear server errors before user has seen them
                        if (!elem.is('[keep-server-errors-on-autocomplete]')) {
                             elem.one("change", remover);
                        }
                        
                        // specific UI control workarounds

                        // datepicker does not send change event when clicking,
                        // so we listen to model change instead
                        if(typeof(elem.attr("datepicker-popup")) != 'undefined'
                            || typeof(elem.attr("uib-datepicker-popup")) != 'undefined'){

                            // for date-picker - just watch the value once and remove the watch
                            var unbinder = $formParentScope.$watch(function() { 
                                    return formfield.$modelValue; 
                                },
                                function(newValue, oldValue){
                                    // prevent fakes for first bind
                                    if(newValue !== oldValue){
                                        remover();
                                        unbinder();
                                    }
                                });
                        }
                    }
                });// foreach
 
                // update UI focus in another context after done with error assignments
                $timeout(function () {
                    if ($firstForm) {
                        $that.validateWithFocus($firstForm);
                    }
                }, 50, false); // keep timer exec order sequential
                
            }, 1, true); //  true - Angular should update binidngs

            return true;
        };
        
        this.focusAutofocus = function (containerSelector) {
     
            $timeout(function () {

                $(containerSelector || "body")
                        .find("[autofocus]")
                        .each(function(){
                            $(this).focus();
                        });
    
            }, 100); // keep noticeable delay to avoid unblock and other glitches

        };
    }

})(window.angular); 