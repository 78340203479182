(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module("amber")
            .controller("PhysicalPersonRegistrationController",
                    ["$scope", "ClientService", "PhysicalPersonRegistrationService", "FormValidatorService",
                        PhysicalPersonRegistrationController]);

    /*
     * Controller for displaying instances
     */
    function PhysicalPersonRegistrationController($scope, ClientService, PhysicalPersonRegistrationService, FormValidatorService) {

        // must be called like this to ensure parent initialization
        PhysicalPersonRegistrationController.parent.constructor.apply(this, [$scope]);

        var vm = this;

        vm.model = {};
        vm.created = false;
        vm.selected = false;

        vm.registerPhysicalPerson = function() {
            if (FormValidatorService.validateWithFocus(vm.form)) {
                PhysicalPersonRegistrationService.save(vm.model, function (response) {
                    vm.model = {};
                    vm.created = true;
                });
            }
        }

        vm.registerPhysicalPersonAndLink = function($token) {
            if (FormValidatorService.validateWithFocus(vm.form)) {
                vm.model.token = $token;

                PhysicalPersonRegistrationService.saveAndLink(vm.model, function (response) {
                    vm.model = {};
                    vm.created = true;
                });
            }
        };

        vm.createNew = function() {

            vm.selected=true;
            // PhysicalPersonRegistrationService.logOut(null, function(response) {
            //     console.log("log");
            //     location.reload();
            // });

        }

        vm.login = function() {
            window.location=window.location.href+'/active/login';
        }
    }

    Utilities.inherit(PhysicalPersonRegistrationController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);

