(function (angular, Utilities) {
    "use strict";

    angular.module('amber')
        .service('RegistrationService',
        ['$resource', RegistrationService]);

    function RegistrationService($resource) {
        return $resource('/api/internal/house-manager-registrations', {}, {
            'save': {
                method: 'POST'
            },
            'subscribe': {
                method: 'POST'
            }
        });
    }

})(window.angular, window.Utilities);