/**
 * Some polyfill from MDN
 * 
 * @author MDN, Māris Seimanovs
 * @copyright MS-IDI, ITExcellence, Amber Housing 2016
 */

/*jshint bitwise: false*/
(function () {
    'use strict';

    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function (fun /*, thisArg */) {

            if (this === void 0 || this === null) {
                throw new TypeError();
            }

            var t = Object(this);
            var len = t.length >>> 0; // Hack to convert object.length to a UInt32
            if (typeof fun !== "function") {
                throw new TypeError();
            }

            var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
            for (var i = 0; i < len; i += 1) {
                if (i in t) {
                    fun.call(thisArg, t[i], i, t);
                }
            }
        };
    }

    if (!Array.prototype.some) {
        Array.prototype.some = function (fun /*, thisArg */) {

            if (this === void 0 || this === null) {
                throw new TypeError();
            }

            var t = Object(this);
            var len = t.length >>> 0; // Hack to convert object.length to a UInt32
            if (typeof fun !== 'function') {
                throw new TypeError();
            }

            var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
            for (var i = 0; i < len; i += 1) {
                if (i in t && fun.call(thisArg, t[i], i, t)) {
                    return true;
                }
            }

            return false;
        };
    }

    if (!Array.prototype.map) {
        Array.prototype.map = function (fun /*, thisArg */) {

            if (this === void 0 || this === null) {
                throw new TypeError();
            }

            var t = Object(this);
            var len = t.length >>> 0; // Hack to convert object.length to a UInt32
            if (typeof fun !== "function") {
                throw new TypeError();
            }

            var res = new Array(len);
            var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
            for (var i = 0; i < len; i += 1) {
                // NOTE: Absolute correctness would demand Object.defineProperty
                //       be used.  But this method is fairly new, and failure is
                //       possible only if Object.prototype or Array.prototype
                //       has a property |i| (very unlikely), so use a less-correct
                //       but more portable alternative.
                if (i in t) {
                    res[i] = fun.call(thisArg, t[i], i, t);
                }
            }

            return res;
        };
    }

    if (!Array.prototype.filter) {
      Array.prototype.filter = function(fun/*, thisArg*/) {

        if (this === void 0 || this === null) {
          throw new TypeError();
        }

        var t = Object(this);
        var len = t.length >>> 0;
        if (typeof fun !== 'function') {
          throw new TypeError();
        }

        var res = [];
        var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
        for (var i = 0; i < len; i++) {
          if (i in t) {
            var val = t[i];

            // NOTE: Technically this should Object.defineProperty at
            //       the next index, as push can be affected by
            //       properties on Object.prototype and Array.prototype.
            //       But that method's new, and collisions should be
            //       rare, so use the more-compatible alternative.
            if (fun.call(thisArg, val, i, t)) {
              res.push(val);
            }
          }
        }

        return res;
      };
    }

    if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function (searchElement, fromIndex) {
            if (this === undefined || this === null) {
                throw new TypeError('"this" is null or not defined');
            }

            var length = this.length >>> 0; // Hack to convert object.length to a UInt32

            fromIndex = +fromIndex || 0;

            if (Math.abs(fromIndex) === Infinity) {
                fromIndex = 0;
            }

            if (fromIndex < 0) {
                fromIndex += length;
                if (fromIndex < 0) {
                    fromIndex = 0;
                }
            }

            for (; fromIndex < length; fromIndex += 1) {
                if (this[fromIndex] === searchElement) {
                    return fromIndex;
                }
            }

            return -1;
        };
    }

    if (!Array.prototype.reduce) {
      Array.prototype.reduce = function(callback /*, initialValue*/) {

        if (this == null) {
          throw new TypeError('Array.prototype.reduce called on null or undefined');
        }
        if (typeof callback !== 'function') {
          throw new TypeError(callback + ' is not a function');
        }
        var t = Object(this), len = t.length >>> 0, k = 0, value;
        if (arguments.length == 2) {
          value = arguments[1];
        } else {
          while (k < len && !(k in t)) {
            k++; 
          }
          if (k >= len) {
            throw new TypeError('Reduce of empty array with no initial value');
          }
          value = t[k++];
        }
        for (; k < len; k++) {
          if (k in t) {
            value = callback(value, t[k], k, t);
          }
        }
        return value;
      };
    }

    if (!Date.prototype.toISOString) {
        (function () {

            function pad(number) {
                if (number < 10) {
                    return '0' + number;
                }
                return number;
            }

            Date.prototype.toISOString = function () {
                return this.getUTCFullYear() +
                  '-' + pad(this.getUTCMonth() + 1) +
                  '-' + pad(this.getUTCDate()) +
                  'T' + pad(this.getUTCHours()) +
                  ':' + pad(this.getUTCMinutes()) +
                  ':' + pad(this.getUTCSeconds()) +
                  '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                  'Z';
            };

        }());
    }

    // https://gist.github.com/chrisjlee/8960575
    if (!document.querySelectorAll) {
      document.querySelectorAll = function (selectors) {
        var style = document.createElement('style'), elements = [], element;
        document.documentElement.firstChild.appendChild(style);
        document._qsa = [];

        style.styleSheet.cssText = selectors + '{x-qsa:expression(document._qsa && document._qsa.push(this))}';
        window.scrollBy(0, 0);
        style.parentNode.removeChild(style);

        while (document._qsa.length) {
          element = document._qsa.shift();
          element.style.removeAttribute('x-qsa');
          elements.push(element);
        }
        document._qsa = null;
        return elements;
      };
    }

    if (!document.querySelector) {
      document.querySelector = function (selectors) {
        var elements = document.querySelectorAll(selectors);
        return (elements.length) ? elements[0] : null;
      };
    }

    if (typeof Object.create !== 'function') {
        (function () {
            var F = function () { };
            Object.create = function (o) {
                if (arguments.length > 1) {
                    throw new Error('Second argument not supported');
                }
                if (o === null) {
                    throw new Error('Cannot set a null [[Prototype]]');
                }
                if (typeof o !== 'object') {
                    throw new TypeError('Argument must be an object');
                }

                F.prototype = o;
                return new F();
            };
        })();
    }

    if (!window.console) {
        var emptyFunction = function () { },
            functionNames = [
                'assert', 'clear', 'count', 'debug', 'dir',
                'dirxml', 'error', 'exception', 'group', 'groupCollapsed',
                'groupEnd', 'info', 'log', 'profile', 'profileEnd', 'table',
                'time', 'timeEnd', 'timeStamp', 'trace', 'warn'
            ];

        // Make sure window.console exists
        window.console = window.console || {};

        // Make sure all functions exist
        functionNames.forEach(function (functionName) {
            window.console[functionName] =
                window.console[functionName] || emptyFunction;
        });
    }

})();