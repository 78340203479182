(function (angular, Utilities, BaseController) {
    "use strict";

    angular.module('amber')
            .controller('InvitationController',
                    ['$scope', 'I18nService', 'FormValidatorService',
                        'AuthService', 'BindingService','$location', "ProfileService","PhysicalPersonRegistrationService",
                        InvitationController]);

    /*
     * Controller for managing almost all profile related actions
     */
    function InvitationController($scope, I18nService, FormValidatorService,
                                     AuthService, BindingService, $location, ProfileService, PhysicalPersonRegistrationService) {
        
        // must be called like this to ensure parent initialization
        InvitationController.parent.constructor.apply(this, 
                                                [$scope, I18nService]);
        
        var vm = this;
        vm.emailSent = false;
        vm.passwordChanged = false;
        vm.model = {};

        vm.initController = function() {
           InvitationController.parent.initController.apply(this, arguments);

            ProfileService.show(null, function(response) {

                vm.user = response.data;
                vm.user.fullName=vm.user.name+" "+vm.user.surname;
                // to prevent typing in form while it still loading
                BindingService.safeApply($scope, function(){
                    PhysicalPersonRegistrationService.checkUserInstance(vm.model , function(response){
                        vm.hasLink=response.data;
                        vm.selected=vm.user.email===vm.model.email;
                        vm.loaded = true;
                    });
                });
            });

        };
        
        vm.sendEmail = function ()
        {
            // hide message, if visible
            vm.emailSent = false;  
            
            if(FormValidatorService.validateWithFocus(vm.form)){
                AuthService.email(vm.model, function(response){
                    vm.emailSent = true;
                    
                    // reset form
                    vm.model = {};
                    FormValidatorService.clearErrors(vm.form);  
                });
            }
        };
        
        vm.acceptInvitation = function (token)
        {
            // hide message, if visibleaasd
            vm.passwordChanged = false;
            
            // set token manually - angular ignores input values
            vm.model.token = token;

            if(FormValidatorService.validateWithFocus(vm.form)){
                AuthService.acceptInvitation(vm.model, function(response) {

                    vm.passwordChanged = true;
                    
                    // reset form
                    vm.model = {};
                    FormValidatorService.clearErrors(vm.form);  
                }, 
                
                // special case - invalid token
                function(response){
                    if(response.data.errors.token){
                        vm.tokenError = response.data.errors.token[0];
                        // reset form
                        vm.model = {};
                        FormValidatorService.clearErrors(vm.form); 
                    }
                });
            }
        };


        vm.useThisAccount = function() {
          vm.selected=true;
        };

        vm.declineInvitation = function(token){
            vm.model.token=token;
            AuthService.declineInvitation(vm.model, function(response) {
                window.location.href=$location.protocol() + "://" + location.host;
            });
        };

        vm.disconnectInvitation = function (token)
        {
            vm.model.token=token;
            AuthService.disconnectInvitation(vm.model, function(response) {
                window.location.href=$location.protocol() + "://" + location.host+'/auth/accept-invitation/'+token+'/active/login';
            });
        };
    }

    // class-like JavaScript extending without Angular heavy-weight logic
    Utilities.inherit(InvitationController, BaseController);

})(window.angular, window.Utilities, window.App.BaseController);